import React, { useEffect, useState } from 'react'

import { Tooltip } from 'react-tippy'
import Warning from 'inter-frontend-svgs/lib/v2/action/stats/warning'
import { ParamsType } from 'src/@types'

import { Input } from '@interco/inter-ui/components/Input'
import { SelectNative } from '@interco/inter-ui/components/SelectNative'
import { Radio } from '@interco/inter-ui/components/Radio'

import TargetRadio from './_targetRadio'
import UserRadio from './_userRadio'
import {
  getValuePlaceholder,
  getValueOptionPlaceholder,
  handleTrackingValue,
  selectInputType,
} from './utils'
import CountrySelect from './_countrySelect'

type TraceFilters = {
  queryString: string
  setQueryString: React.Dispatch<React.SetStateAction<string>>
  setParamsInfoType: (params: ParamsType) => void
}

type InputType =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'tel'
  | 'search'
  | 'url'
  | 'currency'
  | 'time'
  | 'color'

function InfoTypeTraces({ setParamsInfoType, queryString, setQueryString }: TraceFilters) {
  const [selectedRadio, setSelectedRadio] = useState('userAccount')
  const [selectedOption, setSelectedOption] = useState('username')
  const [countryValue, setCountryValue] = useState('')

  function handleInputType(evt: React.FormEvent<HTMLSelectElement>) {
    const { value } = evt.target as HTMLSelectElement
    setSelectedOption(value)
    handleTrackingValue(value, setSelectedRadio)
  }

  useEffect(() => {
    setQueryString('')
    setParamsInfoType({
      value: selectedOption,
      parameter: 'searchFor',
      radio: selectedRadio,
      countryValue,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadio, countryValue])

  useEffect(() => {
    setQueryString('')
    const radioValue = () => {
      switch (selectedOption) {
        case 'username':
          return 'userAccount'
        case 'target':
          return 'email'
        case 'trackingNumber':
          return 'tracking'
        default:
          return ''
      }
    }

    setSelectedRadio(radioValue)
    setParamsInfoType({
      value: selectedOption,
      parameter: 'searchFor',
      radio: selectedRadio,
      countryValue,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  return (
    <>
      <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
        <div className="flex-column">
          Pesquisar por *
          <Tooltip
            title={getValueOptionPlaceholder(selectedOption)}
            position="top"
            trigger="mouseenter"
            theme="light"
            arrow
            animateFill
          >
            <Warning height="15" width="15" className="ml-2" />
          </Tooltip>
        </div>
        <SelectNative
          id="select-native-search-for-name"
          label=""
          value={selectedOption}
          style={{ width: '95%', overflow: 'hidden' }}
          options={[
            {
              text: 'Usuário',
              value: 'username',
            },
            {
              text: 'Não Correntistas/Atualização Cadastral',
              value: 'target',
            },
            {
              text: 'Código de rastreio (Tracking Number)',
              value: 'trackingNumber',
            },
          ]}
          onChange={(evt) => handleInputType(evt)}
        />
      </div>

      <div className="col-sm-12 col-md-6">
        <p className="mb-2 fs-14 text-grayscale--400">Tipo*</p>
        {selectedOption === 'target' ? (
          <TargetRadio selected={selectedRadio} setSelected={setSelectedRadio} />
        ) : (
          ''
        )}
        {selectedOption === 'username' ? (
          <UserRadio selected={selectedRadio} setSelected={setSelectedRadio} />
        ) : (
          ''
        )}
        {selectedOption === 'trackingNumber' ? (
          <Radio
            id="label-tracking"
            value="tracking"
            name="tracking"
            checked
            label="Código de rastreio"
          />
        ) : (
          ''
        )}
        {!selectedOption ? (
          <Radio
            id="label-default"
            value="default"
            name="default"
            label="Selecione a pesquisa "
            disabled
          />
        ) : (
          ''
        )}
      </div>

      <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
        <div className="d-flex">
          {selectedRadio === 'cel' ? <CountrySelect setCountryValue={setCountryValue} /> : ''}
          <div className="w-100">
            <div className="value-tooltip">
              Valor *
              <Tooltip
                title={getValuePlaceholder(selectedOption)}
                position="top"
                trigger="mouseenter"
                theme="light"
                arrow
                animateFill
              >
                <Warning height="15" width="15" className="ml-2" />
              </Tooltip>
            </div>
            <Input
              id="input-search-for-value"
              value={queryString}
              placeholder={selectInputType(selectedRadio).placeholder}
              style={{ width: '100%', overflow: 'hidden', flexGrow: 1 }}
              disabled={selectedRadio === ''}
              mask={selectInputType(selectedRadio, countryValue).mask}
              type={selectInputType(selectedRadio, countryValue).type as InputType}
              onChange={(evt) => setQueryString((evt.target as HTMLSelectElement).value)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoTypeTraces
