import { Trace } from '../@types'
import { serviceValuesMap, statusValuesMap, twilioErrorCodeMap } from './mapValues'

function beautifyDate(date: string) {
  const localDate = new Date(date).toLocaleString('pt-Br')

  return localDate.substring(0, localDate.length - 3).replace(', ', ' - ')
}

function beautifyStatusType(status: Record<string, unknown>) {
  if (status.details) {
    const details = status.details as Record<string, unknown>
    if (details.errorCode && twilioErrorCodeMap[details.errorCode as string]) {
      return twilioErrorCodeMap[details.errorCode as string]
    }
  }
  return statusValuesMap[status.type as string]
}

function beautifyTraceValues(trace: Trace): Trace {
  const beautifiedTrace = { ...trace }

  beautifiedTrace.service = serviceValuesMap[trace.service]
  beautifiedTrace.timestamp = beautifyDate(trace.timestamp)
  if (
    beautifiedTrace.service !== 'Push' &&
    beautifiedTrace.status !== undefined &&
    trace.status !== undefined
  ) {
    beautifiedTrace.status.type = beautifyStatusType(trace.status as Record<string, unknown>)
  }

  return beautifiedTrace
}

function summarizeLabel(label: string | undefined, maxLenght: number) {
  return label && label.length > maxLenght
}

function beautifyAmount(amount: number) {
  if (amount < 1000) return String(amount)
  if (amount < 1000000) return `${(amount / 1000).toFixed(1)} k`
  return `${(amount / 1000000).toFixed(1)} M`
}

function beautifyTimestamp(
  start: string | (string | null | undefined)[] | null | undefined,
  end: string | (string | null | undefined)[] | null | undefined,
) {
  const finalTimestamp = []
  const startArr = start?.toLocaleString().split('-')
  const endArr = end?.toLocaleString().split('-')

  startArr && finalTimestamp.push(`De ${startArr[2]}/${startArr[1]}/${startArr[0]}`)
  endArr
    ? finalTimestamp.push(` até ${endArr[2]}/${endArr[1]}/${endArr[0]}`)
    : finalTimestamp.push(' até Hoje')

  return finalTimestamp.length > 1 ? finalTimestamp.join('') : 'Hoje'
}

function beautifyFilter(value: string): string {
  const formattedValue: string = Date.parse(value)
    ? new Date(value).toLocaleDateString('pt-BR')
    : serviceValuesMap[value] && value
  return formattedValue
}

export {
  beautifyDate,
  beautifyTraceValues,
  beautifyAmount,
  beautifyTimestamp,
  summarizeLabel,
  beautifyFilter,
}
