import styled from 'styled-components'

import { white, grayscale } from '../../styles/colors'

export const Container = styled.div`
  color: ${white};
  width: 100%;
`

export const Table = styled.table`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
`

export const Head = styled.thead`
  margin: 0 0 18px;
`

export const HeadRow = styled.tr`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 20px;
  width: 100%;
`

export const HeadCell = styled.th`
  align-items: center;
  color: ${grayscale[600]};
  display: flex;
  flex-basis: 0;
  flex-flow: row nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  word-break: break-word;
`

export const Body = styled.tbody``

export const BodyRow = styled.tr`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;
  padding: 20px;
  width: 100%;
`

export const BodySubRow = styled.tr`
  background-color: ${grayscale[100]};
  border-radius: 0 0 4px 4px;
  border-top: 1px solid ${grayscale[200]};
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;
  margin-top: -8px;
  padding: 20px;
  width: 100%;
`

export const BodyCell = styled.td`
  align-items: center;
  color: ${grayscale[400]};
  display: flex;
  flex-basis: 0;
  flex-flow: row nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;

  &:first-child {
    padding-left: 0;
  }
`

export const CellText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const PaginationButton = styled.button`
  background: none;
  border: 1px solid ${grayscale[100]};
  border-left: 0;
  color: ${grayscale[400]};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  outline: none;
  height: 40px;
  width: 40px;

  &:disabled {
    background: ${grayscale[100]};
    color: ${grayscale[300]};
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid ${grayscale[100]};
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`

export const UserFeedbackRow = styled(BodyRow)`
  background-color: ${white};
`

export const UserFeedbackCell = styled(BodyCell)`
  justify-content: center;
`
