import React from 'react'

import { Overlay } from '../Modal/styles'
import { Spinner } from './styles'

type LoadingType = {
  isLoading: boolean | undefined
}

export default function Loading({ isLoading }: LoadingType) {
  return (
    <>
      {isLoading ? (
        <Overlay any>
          <Spinner />
        </Overlay>
      ) : (
        ''
      )}
    </>
  )
}
