import React, { useState, useEffect } from 'react'

import Filter from 'src/components/Filter/_index'

import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'

import { getPhoneNumbers, postPhoneNumber } from '../../services/sms'
import * as S from './style'

function Sms() {
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    getPhoneNumbers().then((response) => setPhoneNumbers(response.data))
  }, [])

  function addPhoneNumber() {
    postPhoneNumber(phoneNumber).then((data) => {
      if (!data) {
        setErrorMessage('Erro ao adicionar número.')
      } else {
        getPhoneNumbers().then((response) => setPhoneNumbers(response.data))
        setErrorMessage('')
        setPhoneNumber('')
      }
    })
  }

  return (
    <>
      <h2 className="mb-4 fs-24 fw-700 text-grayscale--500">SMS - Chamada de Voz</h2>
      <Filter collapsable={false} showLabel={false}>
        <div className="row justify-content-between align-items-center">
          <div className="col-6">
            <Input
              id="phoneNumber"
              label="Número do telefone"
              placeholder="Número do telefone: (BR)5531998990090 ou (USA)13029098765"
              style={{ width: '100%', overflow: 'hidden' }}
              value={phoneNumber}
              onChange={(evt) => setPhoneNumber((evt.target as HTMLInputElement).value)}
            />
          </div>
          <div className="col-4">
            <Button variant="primary" style={{ width: '100%' }} onClick={() => addPhoneNumber()}>
              Adicionar
            </Button>
          </div>
        </div>
      </Filter>
      <hr className="my-4" />
      <div className="fs-18 text-grayscale--500 text-center">{errorMessage}</div>
      {phoneNumbers ? (
        <S.Wrapper>
          <div className="row w-100">
            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <div className="d-flex justify-content-between">
                  <span className="fs-16 fw-700 text-grayscale--500 mb-3">
                    Números adicionados:{' '}
                  </span>
                </div>
                {phoneNumbers &&
                  phoneNumbers.map((phone) => <p className="text-grayscale--400 mb-2">{phone}</p>)}
              </S.InfoBox>
            </div>
          </div>
        </S.Wrapper>
      ) : (
        ''
      )}
    </>
  )
}

export default Sms
