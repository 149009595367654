import React from 'react'

import { PieValue } from 'src/@types'

import { Wrapper } from './styles'
import twilio from '../assets/logo-twilio.png'
import zenvia from '../assets/logo-zenvia.png'
import infobip from '../assets/infobip-logo.png'
import defaultLogo from '../assets/company-logo-default.svg'

type DashboardStateChart = {
  providerValue: PieValue
  percentage: number
  total: number
}

function Card({ providerValue, percentage, total }: DashboardStateChart) {
  function getCompanyLogo(company: string) {
    switch (company) {
      case 'twilio':
        return twilio
      case 'zenvia':
        return zenvia
      case 'infobip':
        return infobip
      default:
        return defaultLogo
    }
  }

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Wrapper>
        <img
          src={getCompanyLogo(providerValue.label.toLocaleLowerCase())}
          alt="Logos"
          width={25}
          height={25}
          className="mb-1"
        />
        <span className="text-orange--base fs-18 fw-600 ml-2">{providerValue.label}</span>
        <p className="fs-16 mt-4">
          <strong className="fs-18">{providerValue.count.toLocaleString()}</strong> envios
        </p>
        <p className="fs-14">
          {percentage}% de {total.toLocaleString()} envios totais
        </p>
      </Wrapper>
    </div>
  )
}

export default Card
