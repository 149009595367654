import React, { PropsWithChildren, useState } from 'react'

import ChevronDown from 'inter-frontend-svgs/lib/v2/navigation/chevron-down'
import ChevronUp from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'
import { EnviosType } from 'src/@types'
import * as F from 'src/shared/utils'

import * as S from './styles'
import FilterLabels from './FilterLabels/_index'

type FilterProps = {
  children?: React.ReactNode
  filterParams?: EnviosType
  collapsable?: boolean
  showLabel?: boolean
}

function Filter({
  children,
  filterParams,
  collapsable = true,
  showLabel = true,
}: PropsWithChildren<FilterProps>) {
  const [filterPanelCollapsed, setFilterPanel] = useState(false)
  return (
    <>
      <S.Filter className={filterPanelCollapsed ? 'collapsed' : ''}>
        <S.FilterTitle>
          {showLabel && <p className="fs-18 fw-600 mb-0">Filtros</p>}
          {collapsable ? (
            <button onClick={() => setFilterPanel(!filterPanelCollapsed)} type="button">
              {filterPanelCollapsed ? (
                <ChevronDown color="orange--base" height="30" width="30" />
              ) : (
                <ChevronUp color="orange--base" height="30" width="30" />
              )}
            </button>
          ) : (
            ''
          )}
        </S.FilterTitle>
        <div className="row" style={{ display: filterPanelCollapsed ? 'flex' : 'none' }}>
          {filterParams ? <FilterLabels labels={F.rearrangeFilterParams(filterParams)} /> : ''}
        </div>
        <div style={{ display: filterPanelCollapsed ? 'none' : 'block' }}>{children}</div>
      </S.Filter>
    </>
  )
}

export default Filter
