import React from 'react'

import { Switch, Route } from 'react-router-dom'
import Users from 'src/pages/Users'
import Sms from 'src/pages/Sms'

import ProtectedRoute from '../ProtectedRoute'
import Login from '../../pages/Login'
import LoginSAML from '../../pages/LoginSAML'
import Traces from '../../pages/Traces'
import Dashboard from '../../pages/Dashboard'
import NotFound from '../../pages/NotFound'
import { DASHBOARD, LOGIN, SAML, TRACES, HOME, USERS, SMS } from '../../config/routes'

type LocationState = {
  from: {
    pathname: string
    search: string
  }
}

export default function Routes() {
  const loginData =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? {
          path: SAML,
          component: LoginSAML,
        }
      : {
          path: LOGIN,
          component: Login,
        }

  return (
    <Switch>
      <Route
        exact
        path={loginData.path}
        render={({ location: { state } }) => {
          const { pathname, search } = (state as LocationState).from
          return <loginData.component location={`${pathname}${search}`} />
        }}
      />
      <ProtectedRoute exact path={TRACES}>
        <Traces />
      </ProtectedRoute>
      <ProtectedRoute exact path={DASHBOARD}>
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute exact path={USERS}>
        <Users />
      </ProtectedRoute>
      <ProtectedRoute exact path={SMS}>
        <Sms />
      </ProtectedRoute>
      {/* <ProtectedRoute exact path={INFOS}>
        <Infos />
      </ProtectedRoute> */}
      <ProtectedRoute exact path={HOME}>
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute>
        <NotFound />
      </ProtectedRoute>
    </Switch>
  )
}
