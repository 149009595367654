import React from 'react'

import { CellProps } from 'react-table'
import ChevronUp from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'
import ChevronDown from 'inter-frontend-svgs/lib/v2/navigation/chevron-down'
import ReactTooltip from 'react-tooltip'

import Table from '../../../components/Table'
import SubRow from './SubRow'
import { Trace, TracesTablePagintor } from '../../../@types'
import * as S from './styles'

const bounceTypeToLabel: Record<string, JSX.Element> = {
  MailboxFull: <S.Label not_sent>Enviado</S.Label>,
  Suppressed: <S.Label not_sent>Não Enviado</S.Label>,
  General: <S.Label not_sent>Não Enviado</S.Label>,
  ContentRejected: <S.Label not_sent>Enviado</S.Label>,
  Undetermined: <S.Label not_sent>Não Enviado</S.Label>,
  OnAccountSuppressionList: <S.Label not_sent>Não Enviado</S.Label>,
}

export type TStatus = {
  type?: string
  details?: {
    bounceSubType?: string
  }
}

function getSentCommunicationLabel(
  providerOutput: string | undefined,
  status: TStatus,
  service: string,
): React.ReactNode {
  if (providerOutput?.includes('Pelo menos um destinatário não está na greenlist'))
    return <S.Label not_sent>Não Enviado</S.Label>
  if (service !== 'SMS') return <S.Label success>Enviado</S.Label>
  if (status && status.details && status.details.bounceSubType) {
    return bounceTypeToLabel[status.details.bounceSubType]
  }
  if (status && status.type) {
    switch (status.type) {
      case 'Não enviado':
        return <S.Label not_sent>Não Enviado</S.Label>
      case 'Entregue ao aparelho':
        return <S.Label success>Enviado</S.Label>
      case 'Lido pelo cliente':
        return <S.Label success>Enviado</S.Label>
      default:
        return <S.Label not_sent>Enviado</S.Label>
    }
  }
  return <S.Label not_sent>Enviado</S.Label>
}

function getTraceTypeLabel(trace: Trace): React.ReactNode {
  const { traceType, service, payload } = trace
  const status = trace.status as TStatus
  const providerOutput = payload.details ? payload.details.providerOutput : '-'

  switch (traceType) {
    case 'ERROR':
      if (payload.expected) {
        return <S.Label not_sent>Não Enviado</S.Label>
      }
      return <S.Label error>Erro</S.Label>

    case 'NOT_SENT':
      return <S.Label not_sent>Não Enviado</S.Label>

    case 'REQUEST_INFO':
      if (trace.payload.sendingStatus)
        if (trace.payload.sendingStatus === 'AWAIT_ACTIVE_TIME')
          return <S.Label info>Agendada</S.Label>
      return <S.Label info>Informação</S.Label>

    case 'SENT_COMMUNICATION':
      return getSentCommunicationLabel(providerOutput, status, service)
    default:
      return <div />
  }
}

function setTemplateTooltip(value: string | undefined) {
  return (
    <>
      <ReactTooltip id={value}>
        <span data-tip>{value}</span>
      </ReactTooltip>
      <span data-tip data-for={value}>
        {value}
      </span>
    </>
  )
}

type TracesTableProps = {
  traces: Trace[]
  paginator?: TracesTablePagintor
}

const TracesTable = ({ traces, paginator }: TracesTableProps) => (
  <Table
    columns={React.useMemo(
      () => [
        {
          id: 'icon',
          Header: () => null,
          Cell: ({ row }: CellProps<Trace>) =>
            row.isExpanded ? (
              <S.Icon
                onClick={() => {
                  row.toggleRowExpanded()
                }}
                role="button"
                tabIndex={0}
                onKeyPress={() => 'collapse triggered'}
              >
                <ChevronUp height="15" width="15" color="orange--base" />
              </S.Icon>
            ) : (
              <S.Icon
                onClick={() => {
                  row.toggleRowExpanded()
                }}
                role="button"
                tabIndex={0}
                onKeyPress={() => 'collapse triggered'}
              >
                <ChevronDown height="15" width="15" color="orange--base" />
              </S.Icon>
            ),
          maxWidth: 15,
        },
        { accessor: 'timestamp', Header: 'Data do envio', maxWidth: 175 },
        {
          accessor: 'username',
          Header: 'Usuário',
          maxWidth: 115,
          Cell: ({ row }: CellProps<Trace>) => row.values.username || '',
        },
        {
          accessor: 'target',
          Header: 'Destinatário',
          Cell: ({ row }: CellProps<Trace>) => row.values.target || '',
        },
        { accessor: 'service', Header: 'Serviço', maxWidth: 75 },
        {
          id: 'template',
          Header: 'Template',
          Cell: ({ row }: CellProps<Trace>) =>
            setTemplateTooltip(row.original.eventId || row.original.templateId || ''),
        },
        {
          accessor: 'traceType',
          Header: 'Status',
          Cell: ({ row }: CellProps<Trace>) => getTraceTypeLabel(row.original),
          maxWidth: 106,
        },
      ],
      [],
    )}
    data={React.useMemo(() => traces, [traces])}
    renderRowSubComponent={(row) => <SubRow content={row.original} />}
    paginator={paginator}
  />
)

TracesTable.defaultProps = {
  paginator: null,
}

export default TracesTable
