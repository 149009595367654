export const serviceValuesMap: Record<string, string> = {
  EVENT: 'Evento',
  EMAIL: 'Email',
  EMAIL_ATTACHMENTS: 'Email',
  EMAIL_SENDER: 'Email',
  PUSH: 'Push',
  SMS: 'SMS',
  TOKENS: 'Tokens',
  envios: 'Envios',
  providers: 'Provedores',
}

export const statusValuesMap: Record<string, string> = {
  // SMS status
  Delivered: 'Entregue ao aparelho',
  queued: 'Enviado para a provedora',
  UNDELIVERABLE_NOT_DELIVERED: 'Não entregue',
  undelivered: 'Telefone indisponível ou desligado',
  Undelivered: 'Telefone indisponível ou desligado',
  UNDELIVERABLE_REJECTED_OPERATOR: 'Rejeitado pela operadora',
  delivered: 'Entregue ao aparelho',
  Expired: 'Telefone indisponível ou desligado',
  Undeliverable: 'Telefone indisponível ou desligado',
  REJECTED_PREFIX_MISSING: 'Rejeitado',
  failed: 'Número de telefone inválido',
  EXPIRED_EXPIRED: 'Enviado para a operadora',
  sent: 'Enviado para a operadora',
  'não entregue': 'Telefone indisponível ou desligado',
  NotSent: 'Não enviado',
  'enviado para a operadora': 'Enviado para a operadora',
  FailedDueToInvalidDestinationAddress: 'Número inválido',
  DELIVERED_TO_HANDSET: 'Entregue ao aparelho',
  Sent: 'Enviado para a operadora',
  'entregue no aparelho': 'Entregue ao aparelho',
  falha: 'Número de telefone inválido',
  'Not delivered': 'Não entregue',
  'NotSent: Message Render Failure': 'Falha ao entregar SMS',
  'NotSent: Mobile address is undeliverable': 'Telefone indisponível ou desligado',

  // Email Status
  Bounced: 'Rejeitado',

  // Whatsapp Status
  accepted: 'Aceito pelo Whatsapp',
  read: 'Lido pelo cliente',
  'Expired-1-hour': 'Expirado',
  'Expired-24-hour': 'Expirado',
  error: 'Erro ao enviar',
}

export const twilioErrorCodeMap: Record<string, string> = {
  '21408': 'Envio indisponível para DDI da região destino.',
  '21610': 'Aparelho de destino inacessível',
  '21612': 'Remetente inválido para número de destino',
  '21614': 'Número inválido',
  '21703': 'Aparelho de destino inacessível',
  '30003': 'Aparelho de destino inacessível',
  '30004': 'Mensagem bloqueada',
  '30005': 'Aparelho de destino desconhecido',
  '30006': 'Telefone fixo ou operadora inacessível',
  '30007': 'Aparelho de destino inacessível',
  '30008': 'Aparelho de destino inacessível',
  '30500': 'Aparelho de destino inacessível',
  '60005': 'Aparelho de destino inacessível',
}
