import styled from 'styled-components'

import breakpoints from '../../styles/breakpoints'

export const NotFoundContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  text-align: center;
  width: 100%;

  div {
    img {
      height: 250px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 7rem 0;
  }
`
