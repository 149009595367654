import styled, { css } from 'styled-components'

import { white, grayscale } from '../../styles/colors'

type StyledImageProps = {
  src: string
}

export const VisualizationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
  height: 700px;
  width: 700px;
`

export const PhoneContainer = styled.div`
  font-size: 11px;
  height: 640px;
  position: relative;
  width: 325px;
`

export const PhoneScreen = styled.img`
  position: absolute;
  width: 100%;

  ${({ src }: StyledImageProps) => css`
    src: ${src};
  `}
`

export const PhoneBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 55px 35px;
`

export const PushContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  display: inline-block;
  line-height: 1.4;
  padding: 6px;
  position: relative;
  width: 100%;
`

export const PushHeader = styled.div`
  align-items: center;
  color: ${grayscale[400]};
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  width: 100%;
`

export const PushHeaderInfo = styled.div`
  align-items: center;
  color: ${grayscale[400]};
  display: flex;
  line-height: 1;
`

export const PushHeaderLogo = styled.img`
  height: 16px;
  margin-right: 8px;
  width: 16px;

  ${({ src }: StyledImageProps) => css`
    src: ${src};
  `}
`

export const SMSContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 10px;
  display: inline-block;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 95px;
  padding: 5px 10px;
  position: relative;
  width: 190px;

  &:before {
    background: ${grayscale[100]};
    border-bottom-right-radius: 15px;
    bottom: 0;
    content: '';
    height: 15px;
    left: -7px;
    position: absolute;
    width: 17px;
    z-index: 0;
  }

  &:after {
    background: ${white};
    border-bottom-right-radius: 10px;
    bottom: 0;
    content: '';
    height: 20px;
    left: -10px;
    position: absolute;
    width: 10px;
    z-index: 1;
  }
`
