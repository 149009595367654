import { UsersType } from 'src/@types'

import { request } from '../shared/helpers'
import { GET_USERS } from '../config/urls'

function getUrl(user: string): string {
  return `${GET_USERS}/${user}`
}

export default (user: string, encryptedData = false): Promise<{ data: UsersType }> =>
  request({
    method: 'GET',
    url: getUrl(user),
    headers: { ...(encryptedData && { 'Encrypted-Data': true }) },
  }).then((response) => ({
    data: response.data,
  }))
