import React, { useState } from 'react'

import { UsersType } from 'src/@types'
import Filter from 'src/components/Filter/_index'
import * as U from 'src/shared/utils'
import CopyToClipboard from 'src/components/CopyToClipboard'

import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'

import userService from '../../services/users'
import * as S from './style'

function Users() {
  const [user, setUser] = useState<UsersType>()
  const [userSearch, setUserSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  function onSearch() {
    userService(userSearch).then((response) => {
      if (!response) {
        setErrorMessage('Usuário não encontrado.')
      } else {
        setUser(response.data)
        setErrorMessage('')
      }
    })
  }

  return (
    <>
      <h2 className="mb-4 fs-24 fw-700 text-grayscale--500">Usuários</h2>
      <Filter collapsable={false}>
        <div className="row justify-content-between align-items-center">
          <div className="col-6">
            <Input
              id="username"
              label="Usuário"
              placeholder="Número da conta (9999999) ou código do operador (op9999999)"
              style={{ width: '100%', overflow: 'hidden' }}
              onChange={(evt) => setUserSearch((evt.target as HTMLInputElement).value)}
            />
          </div>
          <div className="col-4">
            <Button variant="primary" style={{ width: '100%' }} onClick={() => onSearch()}>
              Buscar
            </Button>
          </div>
        </div>
      </Filter>
      <hr className="my-4" />
      <div className="fs-18 text-grayscale--500 text-center">{errorMessage}</div>
      {user ? (
        <S.Wrapper>
          <div className="row w-100">
            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <div className="d-flex justify-content-between">
                  <span className="fs-16 fw-700 text-grayscale--500 mb-3">Nome de Usuário: </span>
                  <CopyToClipboard text={user.username} />
                </div>
                <p>{user.username}</p>
              </S.InfoBox>
            </div>

            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <div className="d-flex justify-content-between">
                  <span className="fs-16 fw-700 text-grayscale--500 mb-3">CPF/CNPJ: </span>
                  <CopyToClipboard text={user.cpfCnpj} />
                </div>
                <p>{user.cpfCnpj}</p>
              </S.InfoBox>
            </div>

            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <div className="d-flex justify-content-between">
                  <span className="fs-16 fw-700 text-grayscale--500 mb-3">Nome: </span>
                  <CopyToClipboard text={user.name} />
                </div>
                <p>{user.name}</p>
              </S.InfoBox>
            </div>

            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <div className="d-flex justify-content-between">
                  <span className="fs-16 fw-700 text-grayscale--500 mb-3">Categoria: </span>
                  <CopyToClipboard text={user.category} />
                </div>
                <p>{user.category}</p>
              </S.InfoBox>
            </div>

            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <span className="fs-16 fw-700 text-grayscale--500">Emails: </span>
                <div className="mt-3">
                  {user.emails.map((item) => (
                    <p key={U.handleIndex()}>{item}</p>
                  ))}
                </div>
              </S.InfoBox>
            </div>

            <div className="col-12 col-lg-6">
              <S.InfoBox>
                <span className="fs-16 fw-700 text-grayscale--500">Telefones: </span>
                <div className="mt-3">
                  {user.phoneNumbers.map((item) => (
                    <p key={U.handleIndex()}>{item}</p>
                  ))}
                </div>
              </S.InfoBox>
            </div>

            <div className="col-12">
              <S.InfoBox>
                <span className="fs-16 fw-700 text-grayscale--500">Aparelhos: </span>
                <div className="mt-4">
                  {user.devices.map((item) => (
                    <div key={U.handleIndex()} className="fw-600">
                      <div>
                        Mac Address:
                        <span className="fw-400"> {item.macAddress}</span>
                      </div>
                      <div>
                        Id de Registro:
                        <span className="fw-400"> {item.registrationID}</span>
                      </div>
                      <div>
                        Aplicativo:
                        <span className="fw-400"> {item.app}</span>
                      </div>
                      <div>
                        Versão do Aplicativo:
                        <span className="fw-400"> {item.appVersion}</span>
                      </div>
                      <div>
                        Modelo:
                        <span className="fw-400"> {item.deviceModel}</span>
                      </div>
                      <div>
                        Sistema Operacional:
                        <span className="fw-400"> {item.os}</span>
                      </div>
                      <br />
                    </div>
                  ))}
                </div>
              </S.InfoBox>
            </div>
          </div>
        </S.Wrapper>
      ) : (
        ''
      )}
    </>
  )
}

export default Users
