import React, { useState } from 'react'

import { HandleChangeType } from 'src/@types'

import { DatePicker } from '@interco/inter-ui/components/DatePicker'

type TraceFilters = {
  handleChange: (params: HandleChangeType) => void
}

function DatepickerTracesFilter({ handleChange }: TraceFilters) {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  return (
    <>
      <div className="col-md-12 col-lg-3">
        <DatePicker
          footer
          label="Início"
          hasIcon
          value={startDate}
          onChange={(date) => {
            setStartDate(date)
            handleChange([
              {
                value: date ? date.toISOString().substring(0, 10) : '',
                parameter: 'timestamp[gte]',
              },
            ])
          }}
        />
      </div>
      <div className="col-md-12 col-lg-3">
        <DatePicker
          id="select-native-service"
          label="Fim"
          footer
          hasIcon
          value={endDate}
          onChange={(date) => {
            setEndDate(date)
            handleChange([
              {
                value: date ? date.toISOString().substring(0, 10) : '',
                parameter: 'timestamp[lte]',
              },
            ])
          }}
        />
      </div>
    </>
  )
}

export default DatepickerTracesFilter
