import { createGlobalStyle } from 'styled-components'

import normalize from './normalize'
import menuColors from './menuColors'
import grid from './grid'
import { grayscale, orange, red, generateColorClasses } from './colors'
import {
  generateFontSizeClasses,
  generateFontWeightClasses,
  generateLineHeightClasses,
} from './fonts'
import { generateBorderRadiusClasses } from './radius'

import '../sass/bootstrap.scss'

const globalStyle = createGlobalStyle`
  ${normalize}
  ${grid}
  ${menuColors}


  h1, h2, h3, h4, h5, h6 {
    font-family: 'Sora';
  }
  
  body {
    background-color: white;
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${orange.base};
    text-decoration: none !important;

    &:hover {
      color: ${grayscale['400']};
    }
  }

  .bg-transparent {
    background: transparent;
  }

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateColorClasses(red, 'red')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
`

export default globalStyle
