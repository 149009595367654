import TYPES from './types.json'

export function getValuePlaceholder(searchFor: string | undefined): string {
  switch (searchFor) {
    case 'target':
      return '<b>Informe o destinatário</b> <br>Para telefone: +55 31 00000-0000 <br> Para email: usuario@bancointer.com.br <br> Para Registration ID: fUxnBlH3pkodu4yTPoXfbK:APA9...'

    case 'username':
      return '<b>Informe o usuário</b> <br> Para número da conta: 9999999 <br> Para código do operador: op99999999'

    default:
      return '<b>Informe um valor</b> <br> Para tracking number: EVba728744243348548108c01b6125807b'
  }
}

export function getValueOptionPlaceholder(searchFor: string | undefined): string {
  switch (searchFor) {
    case 'target':
      return '<b>Pesquisa por Destinatário</b> <br>Pesquisa de comunicações transacionais enviadas para clientes sem conta aberta, ou enviadas diretamente para o email/telefone da conta, como em processos de atualização cadastral.'

    case 'username':
      return '<b>Pesquisa por usuário</b> <br>Pesquisa de comunicações transacionais enviadas para clientes com conta aberta.'

    default:
      return '<b>Pesquisa por TrackingNumber</b> <br> Pesquisa de comunicações transacionais pelo código atribuído a elas no momento do envio.'
  }
}

export function selectInputType(type: string, country?: string) {
  switch (type) {
    case 'cel':
      if (country === '55') return TYPES.celBR
      if (country === '1') return TYPES.celUSA
      return TYPES.cel
    case 'email':
      return TYPES.email
    case 'id':
      return TYPES.id
    case 'userAccount':
      return TYPES.userAccount
    case 'operationalCode':
      return TYPES.operationalCode
    case 'tracking':
      return TYPES.tracking
    default:
      return TYPES.default
  }
}

export function handleTrackingValue(value: string, setSelectedRadio: (arg: string) => void) {
  return value === 'trackingNumber' ? setSelectedRadio('tracking') : ''
}

export function handleCountryValue(value: string) {
  switch (value) {
    case '+55':
      return 'celBR'
    case '+1':
      return 'celUSA'
    default:
      return 'cel'
  }
}

export function isCel(value: string) {
  return value === 'cel' || value === 'celBR' || value === 'celUSA'
}
