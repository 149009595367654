import React from 'react'

import PushScreen from '../../assets/images/push-screen.png'
import InterLogo from '../../assets/images/inter-logo.svg'
import * as S from './styles'

type PushProps = {
  message: string
}

function Push({ message }: PushProps) {
  return (
    <S.VisualizationContainer>
      <S.PhoneContainer>
        <S.PhoneScreen src={PushScreen} />
        <S.PhoneBody>
          <S.PushContainer>
            <S.PushHeader>
              <S.PushHeaderInfo>
                <S.PushHeaderLogo src={InterLogo} />
                INTER
              </S.PushHeaderInfo>
              <S.PushHeaderInfo>now</S.PushHeaderInfo>
            </S.PushHeader>
            {message}
          </S.PushContainer>
        </S.PhoneBody>
      </S.PhoneContainer>
    </S.VisualizationContainer>
  )
}

export default Push
