/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import QueryString, { ParsedQuery } from 'query-string'
import 'react-tippy/dist/tippy.css'
import Filter from 'src/components/Filter/_index'

import { HandleChangeType, Trace, TraceFilters } from '../../@types'
import tracesService from '../../services/traces'
import { TRACES } from '../../config/routes'
import { beautifyTraceValues } from '../../shared/beautify'
import TracesTable from './Table'
import TracesFilter from './TracesFilter/_index'

export const TRACES_PER_PAGE = 10

type TracesTableState = {
  traces: Trace[] | undefined
  count: number
}

function Traces() {
  const [state, setState] = useState<TracesTableState>({
    traces: undefined,
    count: 0,
  })
  const history = useHistory()
  const location = useLocation()

  const queryString: ParsedQuery<string | undefined> = QueryString.parse(location.search)

  const getLastTrace = (traces: Trace[]): Trace => {
    let lastTrace: Trace = traces[0]
    let lastTimestamp = lastTrace.timestamp

    traces.forEach((trace) => {
      if (trace.timestamp > lastTimestamp) {
        lastTimestamp = trace.timestamp
        lastTrace = trace
      }
    })

    return lastTrace
  }

  const normalizeTrackingNumber = (traces: Trace[]): Trace[] => {
    const uniquesTrackingNumbersMap: Map<string, Trace[]> = new Map()

    traces.forEach((trace) => {
      const key = trace.trackingNumber
        .concat(trace.service)
        .concat(trace.username ? trace.username : '-')
      if (!uniquesTrackingNumbersMap.has(key)) {
        uniquesTrackingNumbersMap.set(key, [trace])
      } else {
        uniquesTrackingNumbersMap.get(key)?.push(trace)
      }
    }, [])

    const uniquesTrackingNumber: Trace[] = []

    uniquesTrackingNumbersMap.forEach((value, key_) => {
      uniquesTrackingNumber.push(getLastTrace(value))
    }, [])

    return uniquesTrackingNumber
  }

  const doSearch = useCallback(() => {
    history.push(`${TRACES}?${QueryString.stringify(queryString)}`)
    tracesService<Trace[]>(queryString as TraceFilters).then((response) => {
      setState({
        ...state,
        traces: normalizeTrackingNumber(response.data.map((trace) => beautifyTraceValues(trace))),
        count: response.count,
      })
    })
  }, [queryString])

  useEffect(() => {
    history.replace('/consultas', {})
  }, [])

  const handleChange = (params: HandleChangeType): void => {
    params.map((item) => {
      if (item.value === '') {
        delete queryString[item.parameter]
        return ''
      }
      queryString[item.parameter] = item.value
      return ''
    })
  }

  return (
    <>
      <h2 className="mb-4 fs-24 fw-700 text-grayscale--500">Consultas</h2>
      <Filter filterParams={queryString} collapsable={false}>
        <TracesFilter queryString={queryString} doSearch={doSearch} handleChange={handleChange} />
      </Filter>
      <div className="row mt-4">
        <div className="col-12">
          {state.traces && state.traces.length > 0 ? (
            <TracesTable
              traces={state.traces}
              paginator={{
                currentPage:
                  queryString.page && parseInt(queryString.page.toString(), 10) > 0
                    ? parseInt(queryString.page.toString(), 10)
                    : 1,
                setCurrentPage: (page) => {
                  handleChange([{ parameter: 'page', value: page.toString() }])
                  doSearch()
                },
                totalPages: Math.ceil(state.count / TRACES_PER_PAGE),
              }}
            />
          ) : (
            <p className={`fs-16 text-center ${state.traces ? 'd-block' : 'd-none'}`}>
              Nenhum registro foi encontrado
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default Traces
