import { css } from 'styled-components'

import breakpoints from './breakpoints'
import { orange, white, grayscale, green } from './colors'
import { borderRounded } from './radius'

export default css`
  .blog-inter-header {
    &__logo {
      svg path {
        fill: ${orange.base};
      }
    }

    &__menu {
      > nav > button span {
        background-color: ${orange.base};
      }
    }

    &__logo {
      width: calc(100% - 110px);

      @media (min-width: ${breakpoints.md}) {
        width: auto;
      }

      a {
        text-align: center;
        display: block !important;

        @media (min-width: ${breakpoints.md}) {
          display: block;
          width: auto;
          text-align: left;
        }
      }
    }

    &__language-switcher {
      display: none;

      @media (min-width: ${breakpoints.md}) {
        display: block;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    .blog-inter-header {
      &__menu {
        nav > ul > li > a {
          color: ${grayscale['500']};
          font-weight: bold;

          &:hover {
            color: ${orange.base};

            .drop-menu & {
              color: ${white};
            }
          }

          .arrow svg {
            fill: ${orange.base};
          }
        }
      }

      &__account-button {
        background-color: ${orange.base} !important;
        font-weight: 600;

        .drop-menu & {
          background-color: ${green.base} !important;
        }
      }

      &__access-button {
        button {
          color: ${orange.base};
          border: 1px solid ${orange.base};
          font-weight: 600;

          &:hover {
            color: ${orange.base};
            background-color: transparent;
          }

          .drop-menu & {
            border: none;
          }
        }
      }
    }
  }

  .menu-bg-orange {
    background: ${orange.base};

    &__logo {
      width: calc(100% - 80px);

      @media (min-width: ${breakpoints.md}) {
        width: auto;
      }

      a {
        text-align: center;
        display: block !important;

        @media (min-width: ${breakpoints.md}) {
          display: block;
          width: auto;
          text-align: left;
        }
      }
    }

    &__language-switcher {
      display: none;

      @media (min-width: ${breakpoints.md}) {
        display: block;
      }
    }

    &__access-button {
      border: 1px solid ${white} !important;
      border-radius: ${borderRounded['8']};

      @media (min-width: ${breakpoints.lg}) {
        margin-left: 15px;
      }

      @media (min-width: ${breakpoints.xl}) {
        margin-left: 0;
      }

      button {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
`
