import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  p {
    margin-bottom: 0;
  }
`

export const InfoBox = styled.div`
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, 0.46);
  width: 100%;
  min-height: 100px;
  margin: 10px;
  word-break: break-all;
}`
