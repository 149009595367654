import React from 'react'

import InterLogo from 'inter-frontend-svgs/lib/logos/banco-mono'

import { Button } from '@interco/inter-ui/components/Button'

import { useAuth } from '../../hooks'
import { AZURE_URL } from '../../config/urls'
import * as S from './styles'

type LoginSAMLProps = {
  location: string
}

export default function LoginSAML({ location }: LoginSAMLProps) {
  const { auth, signInWithSAML } = useAuth()

  React.useEffect(() => {
    signInWithSAML?.(location)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <S.LoginBackground />
      <S.LoginArea>
        <InterLogo height={50} />
        <S.Title>Painel Comunicador</S.Title>
        {auth?.jwt ? (
          <S.Description>Efetuando login por usuário de rede</S.Description>
        ) : (
          <>
            <S.Description>Efetuar login no Azure AD</S.Description>
            <S.Link href={AZURE_URL}>
              <Button variant="primary" style={{ width: '100%' }}>
                Login
              </Button>
            </S.Link>
          </>
        )}
      </S.LoginArea>
    </>
  )
}
