import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import ConsultasIcon from 'inter-frontend-svgs/lib/v2/navigation/search'
import DashboardIcon from 'inter-frontend-svgs/lib/v2/categories/others'
import UsersIcon from 'inter-frontend-svgs/lib/v2/products/banco/pessoa-fisica'
import SmsIcon from 'inter-frontend-svgs/lib/v2/facilidades/microfone'
import LogoutIcon from 'inter-frontend-svgs/lib/layout/logout'
import ChevronLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'
import ReactTooltip from 'react-tooltip'
import * as U from 'src/shared/utils'

import * as S from './style'
import Logo from './assets/logo.png'
import { TRACES, DASHBOARD, USERS, SMS } from '../../config/routes'
import { useAuth } from '../../hooks'

const Menu = () => {
  const { signOut, auth } = useAuth()
  const [collapsed, setStatus] = useState(true)

  function toggleMenu() {
    setStatus(!collapsed)
  }

  return (
    <S.Wrapper className={collapsed ? 'collapsed' : ''}>
      <div>
        <div className="mb-5 text-center">
          <img src={Logo} alt="Inter Logo" />
          {collapsed ? '' : <p className="text-white">COMUNICADOR</p>}
        </div>
        <Link to={{ pathname: DASHBOARD }}>
          {collapsed ? <ReactTooltip /> : ''}
          <S.MenuItem data-tip="Dashboard">
            <DashboardIcon className="ml-3" color="orange--base" height="25" width="25" />
            {collapsed ? '' : <span>Dashboard</span>}
          </S.MenuItem>
        </Link>
        <Link to={{ pathname: TRACES }}>
          {collapsed ? (
            <ReactTooltip id="consultas-menu" getContent={(dataTip) => `${dataTip}`} />
          ) : (
            ''
          )}
          <S.MenuItem data-tip="Consultas">
            <ConsultasIcon className="ml-3" color="orange--base" height="25" width="25" />
            {collapsed ? (
              ''
            ) : (
              <span data-for="consultas-menu" data-tip="Consultas">
                Consultas
              </span>
            )}
          </S.MenuItem>
        </Link>
        {U.checkAdmin(auth) ? (
          <Link to={{ pathname: USERS }}>
            {collapsed ? <ReactTooltip /> : ''}
            <S.MenuItem data-tip="Usuários">
              <UsersIcon className="ml-3" color="orange--base" height="27" width="27" />
              {collapsed ? '' : <span>Usuários</span>}
            </S.MenuItem>
          </Link>
        ) : (
          ''
        )}
        <Link to={{ pathname: SMS }}>
          {collapsed ? <ReactTooltip /> : ''}
          <S.MenuItem data-tip="SMS - Chamada de Voz">
            <SmsIcon className="ml-3" color="orange--base" height="27" width="27" />
            {collapsed ? '' : <span>Sms - Chamada de Voz</span>}
          </S.MenuItem>
        </Link>
      </div>
      <div>
        <S.Line />
        <ReactTooltip />
        <S.LogoutButton onClick={signOut} data-tip="Sair">
          <LogoutIcon height={20} color="orange--base" />
          {collapsed ? '' : <S.SecondaryMenuText>Sair</S.SecondaryMenuText>}
        </S.LogoutButton>
        <S.CollapsableButton onClick={() => toggleMenu()}>
          {collapsed ? (
            <ChevronRight height={25} color="orange--base" />
          ) : (
            <ChevronLeft height={25} color="orange--base" />
          )}
          {collapsed ? '' : <S.SecondaryMenuText>Menu</S.SecondaryMenuText>}
        </S.CollapsableButton>
      </div>
    </S.Wrapper>
  )
}

export default Menu
