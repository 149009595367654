import React from 'react'

import { Image, SectionContainer, ShadowContainer } from '../styles'
import ClaroImage from '../../assets/Claro-logo.png'
import OiImage from '../../assets/OI-logo.png'
import VivoImage from '../../assets/VIVO-logo.png'
import TimImage from '../../assets/TIM_logo.png'

function DownDetector() {
  return (
    <>
      <p className="fs-18 fw-600 mb-0">Estabilidade das provedoras (DownDetector)</p>
      <SectionContainer>
        <ShadowContainer>
          <a href="https://downdetector.com.br/fora-do-ar/claro/" target="_blank" rel="noreferrer">
            <Image src={ClaroImage} alt="Claro" />
          </a>
        </ShadowContainer>
        <ShadowContainer style={{ padding: '5px 0px' }}>
          <a href="https://downdetector.com.br/fora-do-ar/oi/" target="_blank" rel="noreferrer">
            <Image src={OiImage} alt="Oi" height="90px" />
          </a>
        </ShadowContainer>
        <ShadowContainer style={{ padding: '20px 0px' }}>
          <a href="https://downdetector.com.br/fora-do-ar/vivo/" target="_blank" rel="noreferrer">
            <Image src={VivoImage} alt="Vivo" height="50px" />
          </a>
        </ShadowContainer>
        <ShadowContainer style={{ padding: '21px 0px' }}>
          <a href="https://downdetector.com.br/fora-do-ar/tim/" target="_blank" rel="noreferrer">
            <Image src={TimImage} alt="Tim" height="50px" />
          </a>
        </ShadowContainer>
      </SectionContainer>
    </>
  )
}

export default DownDetector
