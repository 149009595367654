import React from 'react'

import { Link } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'

import { NotFoundContainer } from './style'
import { DASHBOARD } from '../../config/routes'
import NotFoundIcon from '../../assets/images/not-found.png'

const NotFound = () => (
  <NotFoundContainer>
    <div className="d-flex align-items-center justify-content-center">
      <img src={NotFoundIcon} alt="Página não encontrada" />
    </div>
    <h3 className="fw700 fs-30 mt-5">Ops... página não encontrada!</h3>
    <p className="mt-1 mb-5">Não foi possível concluir a solicitação.</p>
    <Link to={DASHBOARD}>
      <Button variant="primary">Voltar para home</Button>
    </Link>
  </NotFoundContainer>
)

export default NotFound
