import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-family: Inter;
  border-collapse: collapse;
  border-spacing: 0 1em;
  background-color: ${grayscale[100]};
  padding: 0 15px;
  border-radius: 4px;
`

export const Th = styled.th`
  font-size: 14px;
  color: ${orange.base};
  background-color: white;
  &:first-child {
    padding: 10px;
  }
  &:last-child {
    padding: 10px;
  }
`

export const Tr = styled.tr`
  margin: 10px 0;
  height: 50px;
  border-radius: 4px;
  &:nth-child(even) {
    background-color: white;
  }
`

export const Td = styled.td`
  padding: 5px;
`
