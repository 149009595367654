import styled, { css } from 'styled-components'

import { white } from '../../../styles/colors'

type StyledLabelProps = {
  success?: boolean
  info?: boolean
  error?: boolean
  not_sent?: boolean
}

export const Icon = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  width: 100%;
`

export const Label = styled.div`
  align-items: center;
  border-radius: 4px;
  color: ${white};
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  justify-content: center;
  width: 96px;

  ${({ success, info, error, not_sent }: StyledLabelProps) => {
    if (success)
      return css`
        background: #20c05d;
      `
    if (info)
      return css`
        background: #40b9ea;
      `
    if (error)
      return css`
        background: #fd4f47;
      `
    if (not_sent)
      return css`
        background: #fcca03;
      `
    return undefined
  }}
`
