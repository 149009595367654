import React, { Dispatch, SetStateAction } from 'react'

import { Radio } from '@interco/inter-ui/components/Radio'

type RadioType = {
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
}

function TargetRadio({ selected, setSelected }: RadioType) {
  return (
    <div className="d-flex">
      <Radio
        className="mr-3"
        id="label-email"
        value="email"
        name="Email"
        checked={selected === 'email'}
        onChange={() => setSelected('email')}
        label="Email"
      />
      <Radio
        className="mr-3"
        id="label-cel"
        value="cel"
        name="Celular"
        checked={selected === 'cel'}
        onChange={() => setSelected('cel')}
        label="Celular"
      />
      <Radio
        id="label-id"
        value="id"
        name="Registration ID"
        checked={selected === 'id'}
        onChange={() => setSelected('id')}
        label="Registration ID"
      />
    </div>
  )
}

export default TargetRadio
