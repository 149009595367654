import { ParsedQuery } from 'query-string'
import { EnviosType, TraceFilters } from 'src/@types'

import { beautifyTimestamp } from './beautify'

export function getDashboardTimestamp(queryString: ParsedQuery<string | undefined>): string {
  return beautifyTimestamp(queryString['timestamp[gte]'], queryString['timestamp[lte]'])
}

export function checkIfEventIsProvider(value: string) {
  return value === 'providers'
}

export function getFilterDetailText(queryState: ParsedQuery<string | undefined>): string {
  return queryState.searchFor === 'providers' ? 'Provedores' : 'Envios'
}

export function handleQueryString(
  queryString: ParsedQuery<string | undefined>,
  parameter: string,
  value: string,
): ParsedQuery<string | undefined> {
  queryString[parameter] = value !== '' ? value : undefined
  return queryString
}

export function rearrangeFilterParams(params: EnviosType | TraceFilters): string[] {
  const values = Object.values(params)
  return values.map(String)
}

export function handleIndex() {
  return Math.floor(Math.random() * 100 + 1) // NOSONAR
}

export function checkAdmin(auth: ({ jwt: string; user: string; role: string } | null) | undefined) {
  const isPrd = process.env.REACT_APP_NODE_ENV === 'production'

  if (!isPrd) return true
  if (isPrd && auth?.role === 'admin') return true
  return false
}

export function calculatePercentage(value: number, total: number) {
  return Number(((value / total) * 100).toFixed(4))
}
