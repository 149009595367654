import styled, { css } from 'styled-components'

import { grayscale } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

type StyledSubRowLineProps = {
  justifyContent: string
}

type StyledSubRowValueProps = {
  capitalize?: boolean
}

export const SubRowContainer = styled.div`
  width: 100%;
`

export const SubRowLine = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent = 'space-between' }: StyledSubRowLineProps) =>
    justifyContent};
  padding-left: 25px;
  padding-left: 25px;
  width: 100%;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const SubRowColumn = styled.div`
  button {
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: ${breakpoints.xl}) {
    margin-bottom: 5px;
  }
`

export const SubRowTitle = styled.div`
  color: ${grayscale[600]};
  font-size: 12px;
  font-weight: 400;
`

export const SubRowValue = styled.div`
  color: ${grayscale[400]};
  font-size: 14px;
  font-weight: 600;

  ${({ capitalize }: StyledSubRowValueProps) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}
`

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`
