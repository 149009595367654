import React from 'react'

import { BillboardValue } from '../../../@types'
import { beautifyAmount } from '../../../shared/beautify'
import * as S from './styles'

type BillboardChartProps = {
  values: BillboardValue[]
}

function BillboardChart({ values }: BillboardChartProps) {
  return (
    <div className="row justify-content-center">
      <div className="col-10">
        <S.BillboardContainer>
          {values.map((BillboardsValue) => (
            <S.BillboardValuesContainer>
              <S.BillboardValue>{beautifyAmount(BillboardsValue.count)}</S.BillboardValue>
              <S.BillboardLabel>{BillboardsValue.label}</S.BillboardLabel>
            </S.BillboardValuesContainer>
          ))}
        </S.BillboardContainer>
      </div>
    </div>
  )
}

export default BillboardChart
