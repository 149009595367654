import React from 'react'

import { ParsedQuery } from 'query-string'
import { useDateConfig } from 'src/hooks/useDateConfig'

import { Button } from '@interco/inter-ui/components/Button'
import { DatePicker } from '@interco/inter-ui/components/DatePicker'
import { Input } from '@interco/inter-ui/components/Input'
import { SelectNative } from '@interco/inter-ui/components/SelectNative'

import filterConfig from './filterConfig.json'

type Props = {
  handleChange: (value: string, parameter: string) => void
  doSearch: (value: boolean) => void
  queryState: ParsedQuery<string | undefined>
  search: boolean
}

function DashboardFilter({ queryState, handleChange, doSearch, search }: Props) {
  const { startDate, endDate } = useDateConfig()

  function handleSelectEvent(evt: React.FormEvent<HTMLSelectElement>) {
    handleChange((evt.target as HTMLSelectElement).value, 'searchFor')
    handleChange('6h', 'period')
    handleChange('1m', 'timeseries')
    doSearch(!search)
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <SelectNative
            id="select-native-search-for-name"
            label="Tipo de Dashboard"
            value={queryState.searchFor?.toString()}
            style={{ width: '100%' }}
            options={[
              {
                text: 'Provedores',
                value: 'providers',
              },
              {
                text: 'Envios',
                value: 'envios',
              },
              {
                text: 'Estabilidade',
                value: 'stability',
              },
            ]}
            onChange={(evt) => handleSelectEvent(evt)}
          />
        </div>
        {queryState.searchFor !== 'stability' && (
          <>
            <div className="col-md-3">
              <DatePicker
                id="select-native-service"
                label="Início"
                maxDate={endDate}
                footer
                hasIcon
                value={
                  queryState['timestamp[gte]']
                    ? new Date(`${queryState['timestamp[gte]']}T00:00:00`)
                    : undefined
                }
                onChange={(date) =>
                  handleChange(date ? date.toISOString().substring(0, 10) : '', 'timestamp[gte]')
                }
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                id="select-native-service"
                label="Fim"
                minDate={startDate}
                maxDate={endDate}
                footer
                hasIcon
                value={
                  queryState['timestamp[lte]']
                    ? new Date(`${queryState['timestamp[lte]']}T00:00:00`)
                    : undefined
                }
                onChange={(date) =>
                  handleChange(date ? date.toISOString().substring(0, 10) : '', 'timestamp[lte]')
                }
              />
            </div>
            <div className="col-sm-3 d-flex align-items-center justify-content-end">
              <Button variant="primary" style={{ width: '100%' }} onClick={() => doSearch(!search)}>
                Buscar
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="row" style={{ display: queryState.searchFor !== 'envios' ? 'none' : 'flex' }}>
        {filterConfig.map((item) => (
          <div className="col-sm-3" key={item.id}>
            <Input
              id={item.id}
              label={item.label}
              placeholder={item.placeholder}
              disabled={queryState.searchFor === '' || queryState.searchFor === 'providers'}
              style={{ width: '100%', overflow: 'hidden' }}
              onChange={(evt) => handleChange((evt.target as HTMLInputElement).value, item.id)}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default DashboardFilter
