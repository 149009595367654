import styled from 'styled-components'

import { grayscale } from '../../styles/colors'

export const Wrapper = styled.div`
  background-color: ${grayscale.base};
  overflow: hidden;
  padding: 20px 0 40px 0;
  width: 250px;
  height: 100vh;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.39);

  img {
    width: 150px;
  }

  &.collapsed {
    width: 60px;

    img {
      width: 40px;
    }
  }
`
export const MenuItem = styled.div`
  &:hover {
    background-color: ${grayscale[400]};
  }

  color: white;
  width: 100%;
  padding: 12px 0;
  font-weight: 600;

  span {
    margin: 0 30px 0 10px;
  }
`

export const LogoutButton = styled.button`
  align-items: center;
  background: transparent;
  display: flex;
  margin-left: 16px;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
  }
`

export const SecondaryMenuText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
`

export const Line = styled.hr`
  border-top-color: ${grayscale[400]};
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
`

export const CollapsableButton = styled.div`
  margin-left: 10px;
  margin-top: 25px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    vertical-align: bottom;
  }
`
