import React, { useMemo, useState } from 'react'

import ReactTooltip from 'react-tooltip'
import { EnviosType } from 'src/@types'
import { summarizeLabel } from 'src/shared/beautify'
import Pagination from 'rc-pagination'
import ChevronLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import * as S from './styles'
import TableConfig from './tableConfig.json'
import '../../../../node_modules/rc-pagination/assets/index.css'

type TableType = {
  values: EnviosType[]
}

const MAX_LENGTH = 30
const POSTS_PER_PAGE = 100

function setLabel(eventLabel: string | boolean | undefined, label: string | undefined) {
  if (eventLabel && label) {
    return <span data-tip data-for={label}>{`${label.substring(0, MAX_LENGTH)}...`}</span>
  }
  if (label) {
    return <span>{label}</span>
  }
  return ''
}

function setEventType(label: string | undefined) {
  const eventLabel = summarizeLabel(label, MAX_LENGTH)
  return (
    <>
      <ReactTooltip id={label}>
        <span data-tip>{label}</span>
      </ReactTooltip>
      <span>{setLabel(eventLabel, label)}</span>
    </>
  )
}

function TableEnvios({ values }: TableType) {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * POSTS_PER_PAGE
    const lastPageIndex = firstPageIndex + POSTS_PER_PAGE
    return values.slice(firstPageIndex, lastPageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, values])

  const onChange = (pageIndex: number) => {
    setCurrentPage(pageIndex)
  }

  const TableHead = () => (
    <S.Tr>
      {TableConfig.tableHeads.map((value) => (
        <S.Th key={value}>{value}</S.Th>
      ))}
    </S.Tr>
  )

  const TableBody = ({ count, eventType, notificationType, service, platform }: EnviosType) => (
    <S.Tr>
      <S.Td>{count}</S.Td>
      <S.Td>{setEventType(eventType)}</S.Td>
      <S.Td>{notificationType}</S.Td>
      <S.Td>{service}</S.Td>
      <S.Td>{platform}</S.Td>
    </S.Tr>
  )

  return (
    <>
      <S.Table>
        <thead>
          <TableHead />
        </thead>
        <tbody>
          {currentTableData.map((value: EnviosType) => (
            <TableBody
              key={`${value.eventType}__${value.notificationType}__${value.service}__${value.platform}`}
              count={value.count}
              eventType={value.eventType}
              notificationType={value.notificationType}
              service={value.service}
              platform={value.platform}
            />
          ))}
        </tbody>
      </S.Table>
      <Pagination
        simple
        style={{ display: 'flex', justifyContent: 'center' }}
        onChange={onChange}
        current={currentPage}
        total={values.length}
        pageSize={100}
        defaultCurrent={1}
        prevIcon={<ChevronLeft color="orange--base" width={30} height={30} />}
        nextIcon={<ChevronRight color="orange--base" width={30} height={30} />}
      />
    </>
  )
}

export default TableEnvios
