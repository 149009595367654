import React from 'react'

import { ResponseType } from 'axios'
import * as U from 'src/shared/utils'
import { Tooltip } from 'react-tippy'

import { Button } from '@interco/inter-ui/components/Button'

import Warning from 'inter-frontend-svgs/lib/v2/action/stats/warning'
import { statusValuesMap } from 'src/shared/mapValues'

import tracesService from '../../../../services/traces'
import { request } from '../../../../shared/helpers'
import { beautifyDate } from '../../../../shared/beautify'
import { Trace } from '../../../../@types'
import Modal from '../../../../components/Modal'
import { Email, Push, SMS } from '../../../../components/Visualization'
import * as S from './styles'
import { useAuth } from '../../../../hooks'

import { TStatus } from '..'

type SubRowProps = {
  content: Trace
}

type Modal = {
  isOpen: boolean
  content?: React.ReactNode
}

const parseBounceSubType: Record<string, string> = {
  MailboxFull: 'Caixa de email do cliente cheia',
  Suppressed: 'Email do cliente inválido',
  General: 'Email do cliente inválido',
  ContentRejected: 'Email rejeitado pelo provedor do cliente',
  Undetermined: 'Rejeitado',
  OnAccountSuppressionList: 'Email do cliente inválido',
}

function parseError(errorMessage: string): string {
  if (errorMessage.includes('NOT_GREENLISTED')) {
    return 'Destinatário Fora da Greenlist de UAT'
  }
  let errorBlocks = errorMessage.split(':', 2)
  if (errorBlocks[1] === null || errorBlocks[1] === undefined || errorBlocks[1] === '') {
    errorBlocks = errorMessage.split('-', 2)
  }

  let finalErrorMsg = errorBlocks[1]
  if (finalErrorMsg?.includes('Tratamento de excessos')) {
    finalErrorMsg =
      'Tratamento de excessos ativado, o intervalo entre os disparos deve ser maior do que 5s. Processamento encerrado.'
  } else {
    finalErrorMsg = errorMessage
  }

  return finalErrorMsg
}

export default function SubRow({ content }: SubRowProps) {
  const [modal, setModal] = React.useState<Modal>({ isOpen: false })
  const { auth } = useAuth()

  const {
    id,
    originSystem,
    timestamp,
    trackingNumber,
    service,
    status,
    traceType,
    eventId,
    templateId,
    username,
    target,
    payload: { details, message, expected, sendingStatus, secondsToSend },
  } = content

  const typedStatus = status as TStatus
  let bounceSubType = 'Entregue'
  const providerOutput = content.payload.details ? content.payload.details.providerOutput : '-'
  if (providerOutput?.includes('Pelo menos um destinatário não está na greenlist'))
    bounceSubType = 'Destinatário Fora da Greenlist de UAT'

  if (typedStatus)
    if (typedStatus.details)
      if (typedStatus.details.bounceSubType)
        bounceSubType = parseBounceSubType[typedStatus.details.bounceSubType]

  let errorMessage = ''
  if (message !== undefined) {
    errorMessage = message
  }

  if (traceType !== 'ERROR') {
    return (
      <>
        <S.SubRowContainer>
          <S.SubRowLine>
            {status && (
              <S.SubRowColumn>
                <S.SubRowTitle>Data do retorno</S.SubRowTitle>
                <S.SubRowValue>
                  {status
                    ? beautifyDate((status.details as Record<string, string>).timestamp)
                    : '-'}
                </S.SubRowValue>
              </S.SubRowColumn>
            )}
            {service === 'SMS' && (
              <S.SubRowColumn>
                <S.SubRowTitle>Provedor</S.SubRowTitle>
                <S.SubRowValue>
                  {details ? getRequestVoice(details.providerName, details.messageId) : '-'}
                </S.SubRowValue>
              </S.SubRowColumn>
            )}
            {status && service !== 'Email' && service !== 'Tokens' && (
              <S.SubRowColumn>
                <S.SubRowTitle>Status do provedor</S.SubRowTitle>
                <S.SubRowValue>
                  {status ? status.type : '-'}
                  {status.type === statusValuesMap.failed && (
                    <Tooltip
                      title="Orientações ao cliente:
                      <ul>
                      <li> Solicitar ao cliente que verifique em seu aparelho os números bloqueados para o recebimento de SMS.</li>
                      <li> Solicitar ao cliente que valide com sua operadora se seu número está desabilitado para recebimento de mensagens de remetentes de código curto (short code)</li>"
                      position="top"
                      trigger="mouseenter"
                      theme="light"
                      arrow
                      animateFill
                    >
                      <Warning height="15" width="15" className="ml-2" />
                    </Tooltip>
                  )}
                </S.SubRowValue>
              </S.SubRowColumn>
            )}
            {(service === 'Email' || service === 'Tokens') && (
              <S.SubRowColumn>
                <S.SubRowTitle>Status do provedor</S.SubRowTitle>
                <S.SubRowValue> {bounceSubType} </S.SubRowValue>
              </S.SubRowColumn>
            )}
            {sendingStatus === 'AWAIT_ACTIVE_TIME' && (
              <S.SubRowColumn>
                <S.SubRowTitle>Status da requisição</S.SubRowTitle>
                <S.SubRowValue>Mensagem agendada</S.SubRowValue>
              </S.SubRowColumn>
            )}
            {secondsToSend && (
              <S.SubRowColumn>
                <S.SubRowTitle>Horário de envio da mensagem:</S.SubRowTitle>
                <S.SubRowValue> {getTimeToSend(timestamp, secondsToSend)} </S.SubRowValue>
              </S.SubRowColumn>
            )}
            {service === 'Email' && (
              <S.SubRowColumn>
                <S.SubRowTitle>Remetente</S.SubRowTitle>
                <S.SubRowValue>{details?.mailFrom}</S.SubRowValue>
              </S.SubRowColumn>
            )}
            <S.SubRowColumn>
              <S.SubRowTitle>Sistema de origem</S.SubRowTitle>
              <S.SubRowValue>{originSystem}</S.SubRowValue>
            </S.SubRowColumn>
            <S.SubRowColumn>
              <S.SubRowTitle>Código de rastreio</S.SubRowTitle>
              <S.SubRowValue>{trackingNumber}</S.SubRowValue>
            </S.SubRowColumn>
          </S.SubRowLine>
          <S.SubRowLine justifyContent="flex-end">
            {traceType === 'SENT_COMMUNICATION' &&
              getTokenStatus(eventId, templateId, service, trackingNumber, username, target) && (
                <>
                  {U.checkAdmin(auth) ? (
                    <S.SubRowColumn>
                      <Button
                        variant="link"
                        onClick={() => {
                          getTraceDetails(id).then(async (data) => {
                            const communication = data.service.replace(/_(.*)/, '')
                            const templateBody =
                              communication === 'EMAIL' || communication === 'TOKENS'
                                ? (await getData(data.payload.body)).data
                                : data.payload.body

                            setModal({
                              isOpen: true,
                              content: renderTemplate(
                                communication,
                                templateBody,
                                data.payload.body,
                              ),
                            })
                          })
                        }}
                      >
                        Visualizar Comunicação
                      </Button>
                    </S.SubRowColumn>
                  ) : (
                    ''
                  )}

                  {U.checkAdmin(auth) && details?.attachments && (
                    <S.SubRowColumn>
                      <Button
                        variant="link"
                        onClick={() => {
                          getTraceDetails(id).then(async (data) => {
                            const attachmentsContent = await Promise.all(
                              (data.payload.details?.attachments || []).map((attachment) =>
                                getData(attachment, 'blob'),
                              ),
                            )

                            attachmentsContent.forEach((attachmentContent, index) => {
                              downloadFile(
                                attachmentContent.data,
                                `anexo-${index + 1}`,
                                attachmentContent.headers['content-type'],
                              )
                            })
                          })
                        }}
                      >
                        Baixar Anexos
                      </Button>
                    </S.SubRowColumn>
                  )}
                </>
              )}
            <S.SubRowColumn>
              <Button
                variant="link"
                onClick={() => {
                  getTraceDetails(
                    id,
                    service === 'Email' || service === 'Sms' || service === 'Tokens',
                  ).then((data) => {
                    downloadFile(JSON.stringify(data, null, 2), 'comprovante', 'application/json')
                  })
                }}
              >
                Baixar Comprovante
              </Button>
            </S.SubRowColumn>
          </S.SubRowLine>
        </S.SubRowContainer>
        <Modal isOpen={modal.isOpen} onClose={() => setModal({ ...modal, isOpen: false })}>
          {modal.content}
        </Modal>
      </>
    )
  }
  return (
    <>
      <S.SubRowContainer>
        <S.SubRowLine>
          <S.SubRowColumn>
            <S.SubRowTitle>Detalhes do {(!expected && 'Erro') || 'Não-envio'}</S.SubRowTitle>
            <S.SubRowValue>
              {expected ? parseError(errorMessage) : 'Erro inesperado: '.concat(errorMessage)}
            </S.SubRowValue>
          </S.SubRowColumn>
        </S.SubRowLine>
      </S.SubRowContainer>
      <Modal isOpen={modal.isOpen} onClose={() => setModal({ ...modal, isOpen: false })}>
        {modal.content}
      </Modal>
    </>
  )
}

function getTokenStatus(
  eventId?: string,
  templateId?: string,
  service?: string,
  trackingNumber?: string,
  username?: string,
  target?: string,
): boolean {
  if (process.env.REACT_APP_NODE_ENV !== 'production') return true
  if (isInternationalCommunication(username, target)) return true
  // if (service === 'Tokens') return false
  /*
  if (
    (trackingNumber && trackingNumber.startsWith('TO')) ||
    (eventId && eventId.toUpperCase().includes('TOKEN')) ||
    (templateId && templateId.toUpperCase().includes('TOKEN'))
  )
    return false
  */
  return true
}

function isInternationalCommunication(username?: string, target?: string) {
  const regexUUID =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return target?.startsWith('1') || (username && regexUUID.test(username))
}

function getRequestVoice(provider?: string, messageId?: string) {
  return messageId && messageId.startsWith('CA') ? `${provider} (VOZ)` : provider
}

function getTraceDetails(id: string, encryptedData?: boolean): Promise<Trace> {
  return tracesService<Trace>({ searchFor: 'id', searchForValue: id }, encryptedData).then(
    (response) => response.data,
  )
}

function downloadFile(file: string, fileName: string, contentType: string): void {
  const blob = new Blob([file], { type: contentType })
  const a = document.createElement('a')

  a.href = window.URL.createObjectURL(blob)
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function getData(url: string, responseType?: ResponseType) {
  return request({ method: 'GET', url, responseType }, true)
}

function getTimeToSend(sentTime: string, secondsToSend: string) {
  const [dayMonthYear, time] = sentTime.split(' - ')
  const [day, month, year] = dayMonthYear.split('/')
  const [hours, minutes] = time.split(':')
  const start = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hours),
    Number(minutes),
  )
  const timeInMilliseconds = start.getTime() + Number(secondsToSend) * 1000
  const result = new Date(timeInMilliseconds)

  const hoursToSend = result.getHours()
  const minutesToSend = result.getMinutes()

  let hourStr
  let minuteStr

  if (hoursToSend < 10) {
    hourStr = `0${hoursToSend}`
  } else hourStr = `${hoursToSend}`

  if (minutesToSend < 10) {
    minuteStr = `0${minutesToSend}`
  } else minuteStr = `${minutesToSend}`

  return `${hourStr}:${minuteStr}`
}

function renderTemplate(communication: string, templateBody: string, emailTemplateURL: string) {
  switch (communication) {
    case 'EMAIL':
    case 'TOKENS':
      return (
        <>
          <Email body={templateBody} />
          <S.DownloadContainer>
            <Button
              variant="primary"
              style={{ minWidth: '150px' }}
              onClick={() => {
                getData(emailTemplateURL, 'blob').then(({ data }) => {
                  downloadFile(data, 'template-email', 'text/html')
                })
              }}
            >
              Baixar Template
            </Button>
          </S.DownloadContainer>
        </>
      )

    case 'PUSH':
      return <Push message={templateBody} />

    case 'SMS':
      return <SMS message={templateBody} />

    default:
      return null
  }
}
