import React from 'react'

import { SelectNative } from '@interco/inter-ui/components/SelectNative'

type CountrySelectType = {
  setCountryValue: React.Dispatch<React.SetStateAction<string>>
}

function CountrySelect({ setCountryValue }: CountrySelectType) {
  return (
    <>
      <SelectNative
        id="select-native-search-for-name"
        label="Selecione o país *"
        style={{ width: '180px', overflow: 'hidden', marginRight: '10px' }}
        options={[
          {
            text: 'Brasil',
            value: '55',
          },
          {
            text: 'EUA',
            value: '1',
          },
          {
            text: 'Outro país',
            value: '',
          },
        ]}
        onChange={(evt) => setCountryValue((evt.target as HTMLSelectElement).value)}
      />
    </>
  )
}

export default CountrySelect
