import React, { useEffect, useState } from 'react'

import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy'
import { ParsedQuery } from 'query-string'
import ChevronDown from 'inter-frontend-svgs/lib/v2/navigation/chevron-down'
import ChevronUp from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'
import { HandleChangeType, ParamsType } from 'src/@types'

import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'
import { SelectNative } from '@interco/inter-ui/components/SelectNative'

import './styles.css'

import DatepickerTracesFilter from './components/_datepicker'
import InfoTypeTraces from './components/_FilterInfoTraces'

export const TRACES_PER_PAGE = 10

type TraceFilters = {
  doSearch: () => void
  handleChange: (params: HandleChangeType) => void
  queryString: ParsedQuery<string | undefined>
}

function TracesFilter({ doSearch, handleChange, queryString }: TraceFilters) {
  const [moreFilters, setMoreFilters] = useState(false)
  const [queryStringInfoType, setQueryStringInfoType] = useState('')
  const [paramsInfoType, setParamsInfoType] = useState<ParamsType>()

  function handleMoreFilters() {
    return moreFilters ? setMoreFilters(false) : setMoreFilters(true)
  }

  const onButtonClick = () => {
    handleChange([{ parameter: 'page', value: '0' }])
    doSearch()
  }

  useEffect(() => {
    if (paramsInfoType) {
      setQueryStringInfoType(queryStringInfoType)

      handleChange([
        { value: paramsInfoType.value, parameter: 'searchFor' },
        {
          value:
            paramsInfoType.radio === 'cel' && paramsInfoType.value
              ? `${paramsInfoType.countryValue}${queryStringInfoType.replace(/\D/g, '')}`
              : queryStringInfoType,
          parameter: 'searchForValue',
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringInfoType])

  return (
    <>
      <div className="row mt-3 aling-items-center">
        <InfoTypeTraces
          queryString={queryStringInfoType}
          setParamsInfoType={setParamsInfoType}
          setQueryString={setQueryStringInfoType}
        />
        <div className="col-sm-12 col-md-2 d-flex align-items-center justify-content-end">
          <Button
            variant="primary"
            style={{ width: '100%' }}
            disabled={!paramsInfoType?.value}
            onClick={onButtonClick}
          >
            Buscar
          </Button>
        </div>
      </div>

      <button type="button" className="button-more" onClick={() => handleMoreFilters()}>
        Outros filtros
        {moreFilters ? (
          <ChevronUp width={20} height={20} color="orange--base" />
        ) : (
          <ChevronDown width={20} height={20} color="orange--base" />
        )}
      </button>

      {moreFilters ? (
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="value-tooltip">
              <Tooltip
                title="ID do template de evento cadastrado em: </br><i>CMS > Comunicador > Eventos </i>"
                position="top"
                trigger="mouseenter"
                theme="light"
                arrow
                animateFill
              >
                ID do evento
              </Tooltip>
            </div>
            <Input
              id="input-event-id"
              placeholder="Informe o ID do evento cadastrado no CMS"
              disabled={queryString.searchFor === '' || queryString.searchFor === 'trackingNumber'}
              style={{ width: '100%', overflow: 'hidden' }}
              onChange={(evt) =>
                handleChange([
                  { value: (evt.target as HTMLInputElement).value, parameter: 'eventId' },
                ])
              }
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="value-tooltip">
              <Tooltip
                title="ID do template avulso cadastrado em: </br><i>CMS > Comunicador > Templates Avulsos</i>"
                position="top"
                trigger="mouseenter"
                theme="light"
                arrow
                animateFill
              >
                ID do template avulso
              </Tooltip>
            </div>
            <Input
              id="input-template-id"
              placeholder="Informe o ID do template avulso cadastrado no CMS"
              disabled={queryString.searchFor === '' || queryString.searchFor === 'trackingNumber'}
              style={{ width: '100%', overflow: 'hidden' }}
              onChange={(evt) =>
                handleChange([
                  { value: (evt.target as HTMLInputElement).value, parameter: 'templateId' },
                ])
              }
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Input
              id="input-origin-system"
              label="Sistema de origem"
              placeholder="Informe o sistema de origem"
              value={queryString.originSystem?.toString()}
              disabled={queryString.searchFor === '' || queryString.searchFor === 'trackingNumber'}
              style={{ width: '100%', overflow: 'hidden' }}
              onChange={(evt) =>
                handleChange([
                  { value: (evt.target as HTMLInputElement).value, parameter: 'originSystem' },
                ])
              }
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <SelectNative
              id="select-native-service"
              label="Serviço"
              value={queryString.type?.toString()}
              disabled={queryString.searchFor !== 'username'}
              style={{ width: '100%', overflow: 'hidden' }}
              options={[
                { text: 'Email', value: 'EMAIL_SENDER' },
                { text: 'Push', value: 'PUSH' },
                { text: 'SMS', value: 'SMS' },
              ]}
              onChange={(evt) =>
                handleChange([{ value: (evt.target as HTMLInputElement).value, parameter: 'type' }])
              }
            />
          </div>
          <DatepickerTracesFilter handleChange={handleChange} />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default TracesFilter
