/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import CopyIcon from 'inter-frontend-svgs/lib/v2/action/copy'

import * as S from './styles'

type CopyToClipboardProps = {
  text: string
}

function CopyToClipboard({ text }: CopyToClipboardProps) {
  return (
    <S.Wrapper onClick={() => navigator.clipboard.writeText(text)}>
      <CopyIcon color="orange--base" height="30" width="30" />
    </S.Wrapper>
  )
}

export default CopyToClipboard
