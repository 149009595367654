import React, { Dispatch, SetStateAction } from 'react'

import { Radio } from '@interco/inter-ui/components/Radio'

type RadioType = {
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
}

function UserRadio({ selected, setSelected }: RadioType) {
  return (
    <div className="d-flex">
      <Radio
        className="mr-3"
        id="label-account"
        value="userAccount"
        name="userAccount"
        checked={selected === 'userAccount'}
        onChange={() => setSelected('userAccount')}
        label="Conta de usuário"
      />
      <Radio
        id="label-code"
        value="operationalCode"
        name="operationalCode"
        checked={selected === 'operationalCode'}
        onChange={() => setSelected('operationalCode')}
        label="Código do operador"
      />
    </div>
  )
}

export default UserRadio
