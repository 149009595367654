import styled from 'styled-components'

import BgLogin from '../../assets/images/bg-login.jpeg'
import { orange, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const LoginBackground = styled.div`
  background: url(${BgLogin}) no-repeat top center;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  width: 100%;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
`

export const LoginArea = styled.div`
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  color: ${white};
  display: flex;
  flex-direction: column;
  float: right;
  height: 100vh;
  justify-content: center;
  padding: 32px;
  position: relative;
  width: 25%;

  .icon {
    fill: ${orange.base};
  }

  @media (max-width: ${breakpoints.xl}) {
    width: 40%;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`

export const Title = styled.h1`
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 20px;
  text-align: center;
`

export const Description = styled.p``

export const Link = styled.a`
  color: ${white};
  width: 100%;

  &:hover {
    color: ${white};
  }
`
