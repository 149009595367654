import styled, { css } from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const ModalArea = styled.div``

export const container = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 100vh;
  overflow: auto;
`

export const Header = styled.header`
  ${container}
`

export const Main = styled.main`
  ${container}
`

export const LayoutHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;

  .icon {
    fill: ${white};
  }

  a,
  button {
    &:hover {
      color: ${grayscale[200]};

      .icon {
        fill: ${grayscale[200]};
      }
    }
  }
`

export const LayoutCard = styled.div`
  min-width: 320px;
  padding: 2rem;
  position: relative;
`

export const HomeLink = styled.a``
