import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 0px;
  border-radius: 8px;
  margin: 15px 15px;
  padding-right: 40px;
  width: 100%;
  height: 300px;
`

export const Image = styled.img``

export const ChartContainer = styled.div`
  padding: 20px 0px;
  border-radius: 8px;
  margin: 15px 15px;
  padding-right: 40px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
`

interface IShadowContainerProps {
  color: string
}

export const DddContainer = styled.div<IShadowContainerProps>`
  width: 98%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  margin: 10px 15px;
`

export const Tablebox = styled.div<IShadowContainerProps>`
  width: 100%;
  max-width: 410px;
  display: flex;
  flex-direction: row;
  margin: 10px 20px;
  margin-left: 0;
`

export const ShadowContainer = styled.div<IShadowContainerProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 15px;
  margin: 0 15px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
  background-color: ${(props: { color: string }) => props.color};
`

export const SectionContainer = styled.div`
  border-radius: 8px;
  margin: 15px 0px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const QueueName = styled.h1`
  font-size: 35px;
  margin: 5px 0;
  font-weight: bold;
`

export const QueueSize = styled.h1`
  font-size: 30px;
  margin: 5px;
`

export const ColorLegend = styled.div`
  display: flex;
  flex-direction: row;
  margin: 100px 0 30px 0;
  padding-right: 70px;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`

export const Color = styled.div<IShadowContainerProps>`
  background-color: ${(props: { color: string }) => props.color};
  width: 100%;
  height: 100%;
`

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 70px;
  margin-bottom: 30px;
`
