import QueryString from 'query-string'

import { TraceFilters } from '../@types'
import { request } from '../shared/helpers'
import {
  TRACES_BY_TARGET_URL,
  TRACES_BY_TRACKING_NUMBER_URL,
  TRACES_BY_USERNAME_URL,
  TRACES_BY_ID_URL,
} from '../config/urls'
import { TRACES_PER_PAGE } from '../pages/Traces'

function getUrl(filters: TraceFilters): string {
  const { searchFor, searchForValue, ...extraFilters } = filters

  extraFilters.limit = TRACES_PER_PAGE
  extraFilters.offset = extraFilters.page && (extraFilters.page - 1) * TRACES_PER_PAGE

  delete extraFilters.page
  switch (searchFor) {
    case 'trackingNumber':
      return `${TRACES_BY_TRACKING_NUMBER_URL}/${searchForValue}`

    case 'target':
      return `${TRACES_BY_TARGET_URL}/${searchForValue}?${QueryString.stringify(extraFilters)}`

    case 'username':
      return `${TRACES_BY_USERNAME_URL}/${searchForValue}?${QueryString.stringify(extraFilters)}`

    case 'id':
      return `${TRACES_BY_ID_URL}/${searchForValue}`

    default:
      throw new Error()
  }
}

export default <T>(
  filters: TraceFilters,
  encryptedData = false,
): Promise<{ data: T; count: number }> =>
  request({
    method: 'GET',
    url: getUrl(filters),
    headers: { ...(encryptedData && { 'Encrypted-Data': true }) },
  }).then((response) => ({
    data: response.data,
    count: response.headers['trace-count'],
  }))
