const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_URL

export const LOGIN_URL = `${SERVICE_BASE_URL}/login`
export const AZURE_URL = `${SERVICE_BASE_URL}/auth/passport`

const TRACES_URL = `${SERVICE_BASE_URL}/traces`
export const TRACES_BY_TARGET_URL = `${TRACES_URL}/by-target`
export const TRACES_BY_TRACKING_NUMBER_URL = `${TRACES_URL}/by-tracking-number`
export const TRACES_BY_USERNAME_URL = `${TRACES_URL}/by-username`
export const TRACES_BY_ID_URL = TRACES_URL

const DASHBOARD_URL = `${SERVICE_BASE_URL}/dashboard`
export const DASHBOARD_PROVIDERS = `${DASHBOARD_URL}/providers`
export const DASHBOARD_ENVIOS_BILLBOARD = `${DASHBOARD_URL}/envios-billboard`
export const DASHBOARD_ENVIOS_PIE = `${DASHBOARD_URL}/envios-pie`
export const ENVIOS_TABLE = `${DASHBOARD_URL}/envios-table`
export const STABILITY_TIME = `${DASHBOARD_URL}/stability/delivery-time/average`
export const STABILITY_AMOUNT = `${DASHBOARD_URL}/stability/delivery`
export const STABILITY_SQS = `${DASHBOARD_URL}/stability/sqs`
export const STABILITY_UNDELIVERED = `${DASHBOARD_URL}/stability/sms-undelivered`

export const GET_USERS = `${SERVICE_BASE_URL}/users`
export const SMS_VOICE_CALL = `${SERVICE_BASE_URL}/sms/voice-call`
