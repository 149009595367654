import React from 'react'

import { beautifyFilter } from 'src/shared/beautify'
import * as U from 'src/shared/utils'

import * as S from './styles'

type FilterProps = {
  labels: string[]
}

function FilterLabels({ labels }: FilterProps) {
  return (
    <S.DashboardInfo>
      {labels.map((label) => (
        <div key={U.handleIndex()}>
          <span className="fw-600 text-grayscale--400">{beautifyFilter(label)}</span>
          {label ? <span className="fw-600 text-orange--base"> | </span> : ''}
        </div>
      ))}
    </S.DashboardInfo>
  )
}

export default FilterLabels
