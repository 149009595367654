import React from 'react'

import Frame from 'react-frame-component'

import * as S from './styles'

type EmailProps = {
  body: string
}

function Email({ body }: EmailProps) {
  return (
    <S.VisualizationContainer>
      <Frame frameBorder="no" style={{ width: '100%', height: '100%' }}>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </Frame>
    </S.VisualizationContainer>
  )
}

export default Email
