import React from 'react'

import { sqsData } from 'src/@types'
import { Tooltip as InfoTooltip } from 'react-tippy'
import Warning from 'inter-frontend-svgs/lib/v2/action/stats/warning'

import { QueueName, QueueSize, SectionContainer, ShadowContainer } from '../styles'

type QueueProps = {
  smsSqs: sqsData
  eventSqs: sqsData
}

function toDateTime(secs: number) {
  const t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs - 10800) // manually conversion to -3 UTC
  return t.toLocaleString()
}

const colorScale = (queueSize: number, QUEUE_THRESHOLD: number) => {
  let calcSize

  if (queueSize <= QUEUE_THRESHOLD) {
    calcSize = queueSize
  } else {
    calcSize = QUEUE_THRESHOLD
  }

  const red = ((255 * calcSize) / (QUEUE_THRESHOLD * 0.7)).toFixed(0)
  let green

  if (calcSize >= 0.7 * QUEUE_THRESHOLD) {
    green = ((230 / 0.3) * (1 - calcSize / QUEUE_THRESHOLD)).toFixed(0)
  } else {
    green = 230
  }

  return 'rgba('.concat(red.toString()).concat(', ').concat(green.toString()).concat(', 0, 0.7)')
}

function Queues({ smsSqs, eventSqs }: QueueProps) {
  return (
    <>
      <div className="value-tooltip d-flex flex-row">
        <p className="fs-18 fw-600 mb-0">
          Quantidade de mensagens aguardando processamento nas filas
        </p>
        <InfoTooltip
          title="Quantidade de mensagens na fila geral do comunicador, e quantidade de mensagens na fila de SMS, aguarando para serem enviadas"
          position="top"
          trigger="mouseenter"
          theme="light"
          arrow
          animateFill
        >
          <Warning height="15" width="15" className="ml-2" />
        </InfoTooltip>
      </div>
      <SectionContainer>
        <ShadowContainer
          style={{
            padding: '20px 10px',
            margin: '40px 40px',
          }}
          color={colorScale(eventSqs.amount, 1000)}
        >
          <QueueName>GERAL</QueueName>
          <QueueSize>{eventSqs.amount}</QueueSize>
          Atualizado em: {toDateTime(eventSqs.timestamp / 1000)}
        </ShadowContainer>
        <ShadowContainer
          style={{
            padding: '20px 10px',
            margin: '40px 40px',
          }}
          color={colorScale(smsSqs.amount, 100)}
        >
          <QueueName>SMS</QueueName>
          <QueueSize>{smsSqs.amount}</QueueSize>
          Atualizado em: {toDateTime(eventSqs.timestamp / 1000)}
        </ShadowContainer>
      </SectionContainer>
    </>
  )
}

export default Queues
