import React from 'react'

import {
  useTable,
  useExpanded,
  usePagination,
  Column,
  Row,
  HeaderGroup,
  ColumnInstance,
  UseExpandedRowProps,
} from 'react-table'

import { Loading as LoadingIcon } from '@interco/inter-ui/components/Loading'

import UserFeedback from './UserFeedback'
import { TracesTablePagintor } from '../../@types'
import { orange } from '../../styles/colors'
import * as S from './styles'

type TableProps<T extends Record<string, unknown>> = {
  data: T[]
  columns: Column<T>[]
  onRowClick?: (row: UseExpandedRowProps<T>) => void
  renderRowSubComponent?: (row: Row<T>) => React.ReactNode
  paginator?: TracesTablePagintor
  isLoading?: boolean
}

function getCellStyle(column: HeaderGroup | ColumnInstance) {
  return {
    maxWidth: column.maxWidth,
    minWidth: column.minWidth,
  }
}
function Table<T extends Record<string, unknown>>({
  data,
  columns,
  onRowClick,
  renderRowSubComponent,
  paginator,
  isLoading,
}: TableProps<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } =
    useTable<T>(
      {
        columns,
        data,
        ...(paginator && {
          useControlledState: (state) =>
            React.useMemo(
              () => ({
                ...state,
                pageIndex: paginator.currentPage,
              }),
              [state, paginator.currentPage], // eslint-disable-line react-hooks/exhaustive-deps
            ),
        }),
      },
      useExpanded,
      usePagination,
    )

  return (
    <S.Container>
      <S.Table {...getTableProps()}>
        <S.Head>
          {headerGroups.map((headerGroup) => (
            <S.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <S.HeadCell
                  {...column.getHeaderProps({
                    style: getCellStyle(column as HeaderGroup<Record<string, unknown>>),
                  })}
                >
                  {column.render('Header')}
                </S.HeadCell>
              ))}
            </S.HeadRow>
          ))}
        </S.Head>
        <S.Body {...getTableBodyProps()}>
          {!isLoading &&
            rows.map((row) => {
              prepareRow(row)

              return (
                <React.Fragment key={row.getRowProps().key}>
                  <S.BodyRow
                    onClick={() => {
                      onRowClick?.(row)
                    }}
                  >
                    {row.cells.map((cell) => (
                      <S.BodyCell
                        {...cell.getCellProps({
                          style: getCellStyle(
                            cell.column as ColumnInstance<Record<string, unknown>>,
                          ),
                        })}
                      >
                        <S.CellText onClick={(evt: Event) => evt.stopPropagation()}>
                          {cell.render('Cell')}
                        </S.CellText>
                      </S.BodyCell>
                    ))}
                  </S.BodyRow>
                  {renderRowSubComponent && row.isExpanded ? (
                    <S.BodySubRow>
                      <S.BodyCell colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </S.BodyCell>
                    </S.BodySubRow>
                  ) : null}
                </React.Fragment>
              )
            })}
        </S.Body>
      </S.Table>
      {isLoading && (
        <UserFeedback>
          <LoadingIcon color={orange.base} />
        </UserFeedback>
      )}
      {paginator && (
        <>
          <div className="d-flex justify-content-center p-4">
            <S.PaginationButton
              onClick={() => {
                paginator.setCurrentPage(1)
              }}
              disabled={paginator.currentPage === 1}
            >
              «
            </S.PaginationButton>
            <S.PaginationButton
              onClick={() => {
                paginator.setCurrentPage(paginator.currentPage - 1)
              }}
              disabled={paginator.currentPage === 1}
            >
              ‹
            </S.PaginationButton>
            <S.PaginationButton>{paginator.currentPage}</S.PaginationButton>
            <S.PaginationButton
              onClick={() => {
                paginator.setCurrentPage(paginator.currentPage + 1)
              }}
              disabled={paginator.currentPage === paginator.totalPages}
            >
              ›
            </S.PaginationButton>
            <S.PaginationButton
              onClick={() => {
                paginator.setCurrentPage(paginator.totalPages)
              }}
              disabled={paginator.currentPage === paginator.totalPages}
            >
              »
            </S.PaginationButton>
          </div>
        </>
      )}
    </S.Container>
  )
}

Table.defaultProps = {
  onRowClick: null,
  renderRowSubComponent: null,
  paginator: null,
  isLoading: false,
}

export default Table
