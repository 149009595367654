import React from 'react'

import axios from 'axios'

import ErrorModal from '../components/ErrorModal'

export default function withRequest<T extends Record<string, unknown>>(
  WrappedComponent: React.FC<T>,
) {
  return (props: T) => {
    const [requestError, setRequestError] = React.useState(false)

    React.useEffect(() => {
      axios.interceptors.request.use((request) => request)

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          setRequestError(true)
          return Promise.reject(error)
        },
      )
    }, [])

    return (
      <>
        <WrappedComponent {...props} />
        <ErrorModal isOpen={requestError} closeModal={() => setRequestError(false)} />
      </>
    )
  }
}
