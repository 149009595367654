import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import '@interco/inter-ui/inter-ui.css'

import { AuthContextProvider } from './contexts/AuthContext'
import Routes from './components/Routes'

function App() {
  const auth = sessionStorage.getItem('auth')

  return (
    <Router>
      <CookiesProvider>
        <AuthContextProvider authentication={auth && JSON.parse(auth)}>
          <div style={{ display: 'flex' }}>
            <Routes />
          </div>
        </AuthContextProvider>
      </CookiesProvider>
    </Router>
  )
}

export default App
