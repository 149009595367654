import QueryString from 'query-string'

import { TraceFilters } from '../@types'
import { request } from '../shared/helpers'
import {
  DASHBOARD_PROVIDERS,
  DASHBOARD_ENVIOS_BILLBOARD,
  ENVIOS_TABLE,
  STABILITY_TIME,
  STABILITY_SQS,
  STABILITY_AMOUNT,
  STABILITY_UNDELIVERED,
} from '../config/urls'

function getUrl(filters: TraceFilters, url: string): string {
  const { ...extraFilters } = filters

  switch (url) {
    case 'PROVIDERS':
      return `${DASHBOARD_PROVIDERS}?${QueryString.stringify(extraFilters)}`

    case 'ENVIOS_BILLBOARD':
      return `${DASHBOARD_ENVIOS_BILLBOARD}?${QueryString.stringify(extraFilters)}`

    case 'ENVIOS':
      return `${ENVIOS_TABLE}?${QueryString.stringify(extraFilters)}`

    case 'STABILITY_TIME':
      return `${STABILITY_TIME}?${QueryString.stringify(extraFilters)}`

    case 'STABILITY_AMOUNT':
      return `${STABILITY_AMOUNT}?${QueryString.stringify(extraFilters)}`

    case 'STABILITY_SQS':
      return `${STABILITY_SQS}?${QueryString.stringify(extraFilters)}`

    case 'STABILITY_UNDELIVERED':
      return `${STABILITY_UNDELIVERED}?${QueryString.stringify(extraFilters)}`

    default:
      throw new Error()
  }
}

export default <T>(
  filters: TraceFilters,
  url: string,
  encryptedData = false,
): Promise<{ data: T }> =>
  request({
    method: 'GET',
    url: getUrl(filters, url),
    headers: { ...(encryptedData && { 'Encrypted-Data': true }) },
  }).then((response) => ({
    data: response.data,
  }))
