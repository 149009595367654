import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../../hooks'
import Layout from '../Layout'
import { LOGIN, SAML, USERS } from '../../config/routes'

type ProtectedRouteProps = {
  children: React.ReactNode
  path?: string
  exact?: boolean
}

export default function ProtectedRoute({ children, ...props }: ProtectedRouteProps) {
  const { auth } = useAuth()

  const pathname = process.env.REACT_APP_NODE_ENV === 'production' ? SAML : LOGIN

  function renderRoute(locationPath: string) {
    if (!auth) {
      return false
    }
    if (locationPath === USERS && auth?.role !== 'admin') {
      return false
    }
    return true
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        renderRoute(location.pathname) ? (
          <Layout>{children}</Layout>
        ) : (
          <Redirect to={{ pathname, state: { from: location } }} />
        )
      }
    />
  )
}

ProtectedRoute.defaultProps = {
  path: undefined,
  exact: false,
}
