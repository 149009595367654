import { request } from '../shared/helpers'
import { SMS_VOICE_CALL } from '../config/urls'

function getUrl(phoneNumber: string): string {
  return `${SMS_VOICE_CALL}/${phoneNumber}`
}

export function postPhoneNumber(phoneNumber: string): Promise<{ data: string }> {
  return request({
    method: 'POST',
    url: getUrl(phoneNumber),
  }).then((response) => ({
    data: response.data,
  }))
}

export function getPhoneNumbers(): Promise<{ data: string[] }> {
  return request({
    method: 'GET',
    url: SMS_VOICE_CALL,
  }).then((response) => ({
    data: response.data,
  }))
}
