import axios, { Method, AxiosPromise, ResponseType } from 'axios'

type RequestConfig = {
  method: Method
  url: string
  headers?: Record<string, string | number | boolean>
  body?: Record<string, unknown>
  responseType?: ResponseType
}

function request(
  { method, url, headers = {}, body, responseType }: RequestConfig,
  ignoreAuthorizationHeader?: boolean,
): AxiosPromise {
  const auth = sessionStorage.getItem('auth')
  const jwt = !ignoreAuthorizationHeader && auth && JSON.parse(auth).jwt

  return axios({
    method,
    url,
    headers: {
      ...headers,
      ...(jwt && { Authorization: `Bearer ${jwt}` }),
    },
    responseType,
    data: body,
  })
}

function setQueryParams(filters: { name: string; value: string }[]): string {
  return filters.map((filter) => `${filter.name}=${filter.value}`).join('&')
}

export { request, setQueryParams }
