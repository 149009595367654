import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import QueryString, { ParsedQuery } from 'query-string'
import * as F from 'src/shared/utils'
import { DASHBOARD } from 'src/config/routes'
import Loading from 'src/components/Loading/Loading'
import { useLoading } from 'src/hooks/useLoading'

import Table from './Table/_index'
import {
  EnviosType,
  PieValue,
  BillboardValue,
  DashboardFilters,
  DashboardState,
  TimeData,
  CountData,
  sqsData,
  DddData,
} from '../../@types'
import dashboardService from '../../services/dashboard'
import * as S from './styles'
import BillboardChart from './BillboadChart/_index'
import './styles.css'
import Filter from '../../components/Filter/_index'
import DashboardFilter from './DashboardFilter/_index'
import Card from './Card/_Card'
import Stability from './Stability/_index'

function Dashboard() {
  const [state, setState] = useState<DashboardState>({
    providersValues: [],
    enviosBillboardValues: [],
    enviosValues: [],
    tokensTime: [],
    tokensCount: [],
    smsTime: [],
    smsCount: [],
    smsSqs: {
      amount: 0,
      timestamp: 0,
    },
    eventSqs: {
      amount: 0,
      timestamp: 0,
    },
    dddData: [],
  })

  const [queryState, setQueryState] = useState<ParsedQuery<string | undefined>>({
    searchFor: 'providers',
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState(1)
  const [search, doSearch] = useState(true)

  const history = useHistory()
  const { isLoading, setLoadingState } = useLoading()

  useEffect(() => {
    switch (queryState.searchFor) {
      case 'providers':
        setLoadingState(true)
        dashboardService<PieValue[]>(queryState as DashboardFilters, 'PROVIDERS').then(
          (response) => {
            const reducedSum = response.data
              .map((values) => values.count)
              .reduce((accumulator, currentValue) => accumulator + currentValue)
            setTotal(reducedSum)
            setState((prevState) => ({
              ...prevState,
              providersValues: response.data,
            }))
            setLoadingState(false)
          },
        )
        break

      case 'envios':
        setLoadingState(true)
        dashboardService<BillboardValue[]>(queryState as DashboardFilters, 'ENVIOS_BILLBOARD').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              enviosBillboardValues: response.data,
            }))
          },
        )
        dashboardService<EnviosType[]>(queryState as DashboardFilters, 'ENVIOS').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              enviosValues: response.data,
            }))
            setLoadingState(false)
          },
        )
        break
      case 'stability':
        handleChange('TOKENS', 'service')
        dashboardService<TimeData[]>(queryState as DashboardFilters, 'STABILITY_TIME').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              tokensTime: response.data,
            }))
            setLoadingState(false)
          },
        )
        dashboardService<CountData[]>(queryState as DashboardFilters, 'STABILITY_AMOUNT').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              tokensCount: response.data,
            }))
            setLoadingState(false)
          },
        )
        handleChange('SMS', 'service')
        dashboardService<TimeData[]>(queryState as DashboardFilters, 'STABILITY_TIME').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              smsTime: response.data,
            }))
            setLoadingState(false)
          },
        )
        dashboardService<CountData[]>(queryState as DashboardFilters, 'STABILITY_AMOUNT').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              smsCount: response.data,
            }))
            setLoadingState(false)
          },
        )
        handleChange('COMNC-PRD-EVENTS', 'queue')
        dashboardService<sqsData>(queryState as DashboardFilters, 'STABILITY_SQS').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              eventSqs: response.data,
            }))
            setLoadingState(false)
          },
        )
        handleChange('COMNC-PRD-SMS_SEND_TEMPLATE', 'queue')
        dashboardService<sqsData>(queryState as DashboardFilters, 'STABILITY_SQS').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              smsSqs: response.data,
            }))
            setLoadingState(false)
          },
        )
        dashboardService<DddData[]>(queryState as DashboardFilters, 'STABILITY_UNDELIVERED').then(
          (response) => {
            setState((prevState) => ({
              ...prevState,
              dddData: response.data,
            }))
            setLoadingState(false)
          },
        )
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleChange = (value: string, parameter: string): void => {
    F.checkIfEventIsProvider(value) ? (queryState.eventId = '') : ''

    setQueryState({ ...F.handleQueryString(queryState, parameter, value) })

    history.push(`${DASHBOARD}?${QueryString.stringify(queryState)}`)
  }

  const loadComponent = (searchFor: string) => {
    switch (searchFor) {
      case 'envios':
        return (
          <>
            <BillboardChart values={state.enviosBillboardValues} />
            {state.enviosValues.length > 0 && <Table values={state.enviosValues} />}
          </>
        )
      case 'stability':
        return (
          <>
            <Stability
              tokensTime={state.tokensTime}
              tokensCount={state.tokensCount}
              smsTime={state.smsTime}
              smsCount={state.smsCount}
              smsSqs={state.smsSqs}
              eventSqs={state.eventSqs}
              // dddData={state.dddData}
            />
          </>
        )
      default:
        return (
          <S.Row>
            {state.providersValues.map((providerValue) => (
              <Card
                key={providerValue.label}
                providerValue={providerValue}
                percentage={F.calculatePercentage(providerValue.count, total)}
                total={total}
              />
            ))}
          </S.Row>
        )
    }
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <h2 className="mb-4 fs-24 fw-700 text-grayscale--500">Dashboard</h2>
      <Filter filterParams={queryState} collapsable={false}>
        <DashboardFilter
          handleChange={handleChange}
          queryState={queryState}
          doSearch={doSearch}
          search={search}
        />
      </Filter>
      <S.Dashboard> {loadComponent(queryState.searchFor as string)} </S.Dashboard>
    </>
  )
}

export default Dashboard
