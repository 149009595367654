import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const DashboardInfo = styled.div`
  padding: 5px 10px;
  border-radius: 8px;
  margin: 15px 15px;
  background-color: ${grayscale[100]};
  display: flex;
  width: fit-content;
`
