import React from 'react'

import SMSScreen from '../../assets/images/sms-screen.png'
import * as S from './styles'

type SMSProps = {
  message: string
}

function SMS({ message }: SMSProps) {
  return (
    <S.VisualizationContainer>
      <S.PhoneContainer>
        <S.PhoneScreen src={SMSScreen} />
        <S.PhoneBody>
          <S.SMSContainer>{message}</S.SMSContainer>
        </S.PhoneBody>
      </S.PhoneContainer>
    </S.VisualizationContainer>
  )
}

export default SMS
