import React from 'react'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts'
import { Tooltip as InfoTooltip } from 'react-tippy'
import Warning from 'inter-frontend-svgs/lib/v2/action/stats/warning'

import { CountData, /* DddData, */ TimeData, sqsData } from '../../../@types'
import { Container, ChartContainer } from './styles'
import DownDetector from './DownDetector/_index'
import Queues from './Queues/_index'
// import DddMap from './UndeliveredMap/_inter'

type StabilityProps = {
  tokensCount: CountData[]
  tokensTime: TimeData[]
  smsCount: CountData[]
  smsTime: TimeData[]
  smsSqs: sqsData
  eventSqs: sqsData
  // dddData: DddData[]
}

const parseValuesTime = (values: TimeData[]) =>
  values.map((value) => ({
    dateLabel: toDateTime(value.beginTimeSeconds)
      .concat(' até ')
      .concat(toDateTime(value.endTimeSeconds)),
    averageTime: value.averageTime,
  }))

const parseValuesCount = (values: CountData[]) =>
  values.map((value) => ({
    dateLabel: toDateTime(value.beginTimeSeconds)
      .concat(' até ')
      .concat(toDateTime(value.endTimeSeconds)),
    count: value.count,
  }))

const formatXAxis = (timeStamp: string) => timeStamp.slice(12, 17)

function toDateTime(secs: number) {
  const t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs - 10800) // manually conversion to -3 UTC
  return t.toLocaleString()
}

function Stability({
  tokensCount,
  tokensTime,
  smsCount,
  smsTime,
  smsSqs,
  eventSqs,
}: StabilityProps) {
  /* dddData, */
  const tokensIimeData = parseValuesTime(tokensTime)
  const tokensCountData = parseValuesCount(tokensCount)
  const smsTimeData = parseValuesTime(smsTime)
  const smsCountData = parseValuesCount(smsCount)
  return (
    <Container>
      <DownDetector />
      <Queues smsSqs={smsSqs} eventSqs={eventSqs} />
      <div className="value-tooltip d-flex flex-row">
        <p className="fs-18 fw-600 mb-0">Envio de EMAIL por minuto</p>
        <InfoTooltip
          title="Quantidade de emails enviados por minuto nas últimas 6 horas, e o tempo médio decorrido para enviar os emails em segundos"
          position="top"
          trigger="mouseenter"
          theme="light"
          arrow
          animateFill
        >
          <Warning height="15" width="15" className="ml-2" />
        </InfoTooltip>
      </div>
      <ChartContainer>
        <ResponsiveContainer width="47%" height="100%">
          <LineChart data={tokensCountData} margin={{ top: 0, right: 40, left: 10, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateLabel"
              tickFormatter={formatXAxis}
              label={{
                value: 'Horário',
                position: {
                  x: '50%',
                  y: '140%',
                },
              }}
            />
            <YAxis
              label={{
                value: 'Quantidade de envios por minuto',
                angle: 270,
                position: {
                  x: '15%',
                  y: '10%',
                },
              }}
            />
            <Tooltip
              formatter={(value, name) => [
                value,
                name.toString().replace('averageTime', 'Tempo Médio'),
              ]}
            />
            <Line type="monotone" dataKey="count" stroke="#F79D42" dot={false} />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="53%" height="100%">
          <LineChart data={tokensIimeData} margin={{ top: 0, right: 0, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateLabel"
              tickFormatter={formatXAxis}
              label={{
                value: 'Horário',
                position: {
                  x: '50%',
                  y: '140%',
                },
              }}
            />
            <YAxis
              label={{
                value: 'Tempo de entrega EMAIL (Em segundos)',
                angle: 270,
                position: {
                  x: '-10%',
                  y: '4%',
                },
              }}
            />
            <Tooltip
              formatter={(value, name) => [
                Number.parseFloat(value.toString()) < 0.01
                  ? '0.01 segundos'
                  : Number.parseFloat(value.toString()).toFixed(2).toString().concat(' segundos'),
                name.toString().replace('averageTime', 'Tempo Médio'),
              ]}
            />
            <Line type="monotone" dataKey="averageTime" stroke="#F79D42" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>

      <div className="value-tooltip d-flex flex-row">
        <p className="fs-18 fw-600 mb-0">Envio de SMS por minuto</p>
        <InfoTooltip
          title="Quantidade de SMS enviados por minuto nas últimas 6 horas, e o tempo médio decorrido para enviar os sms em segundos"
          position="top"
          trigger="mouseenter"
          theme="light"
          arrow
          animateFill
        >
          <Warning height="15" width="15" className="ml-2" />
        </InfoTooltip>
      </div>
      <ChartContainer>
        <ResponsiveContainer width="47%" height="100%">
          <LineChart data={smsCountData} margin={{ top: 0, right: 40, left: 10, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateLabel"
              tickFormatter={formatXAxis}
              label={{
                value: 'Horário',
                position: {
                  x: '50%',
                  y: '140%',
                },
              }}
            />
            <YAxis
              label={{
                value: 'Quantidade de envios por minuto',
                angle: 270,
                position: {
                  x: '15%',
                  y: '10%',
                },
              }}
            />
            <Tooltip
              formatter={(value, name) => [
                value,
                name.toString().replace('averageTime', 'Tempo Médio'),
              ]}
            />
            <Line type="monotone" dataKey="count" stroke="#F79D42" dot={false} />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="53%" height="100%">
          <LineChart data={smsTimeData} margin={{ top: 0, right: 0, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateLabel"
              tickFormatter={formatXAxis}
              label={{
                value: 'Horário',
                position: {
                  x: '50%',
                  y: '140%',
                },
              }}
            />
            <YAxis
              label={{
                value: 'Tempo de entrega SMS (Em segundos)',
                angle: 270,
                position: {
                  x: '-10%',
                  y: '4%',
                },
              }}
            />
            <Tooltip
              formatter={(value, name) => [
                Number.parseFloat(value.toString()) < 0.01
                  ? '0.01 segundos'
                  : Number.parseFloat(value.toString()).toFixed(2).toString().concat(' segundos'),
                name.toString().replace('averageTime', 'Tempo Médio'),
              ]}
            />
            <Line type="monotone" dataKey="averageTime" stroke="#F79D42" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
      {/* <DddMap dddData={dddData} /> */}
    </Container>
  )
}

export default Stability
