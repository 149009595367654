import { useEffect, useState, useMemo } from 'react'

import { subYears } from 'date-fns'

export function useDateConfig() {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const currentDate = useMemo(() => new Date(), [])

  useEffect(() => {
    const today = new Date()
    setEndDate(today)
    setStartDate(subYears(today, 10))
  }, [currentDate])

  return {
    startDate,
    endDate,
  }
}
