import React from 'react'

import { withRequest } from '../../hocs'
import * as S from './styles'
import Menu from '../Menu/index'

type LayoutProps = {
  children: React.ReactNode
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <Menu />
      <S.ModalArea id="modal-area" />
      <S.Main>
        <S.LayoutCard>{children}</S.LayoutCard>
      </S.Main>
    </>
  )
}

export default withRequest<LayoutProps>(Layout)
