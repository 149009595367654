import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const DashboardInfo = styled.div`
  padding: 5px 10px;
  border-radius: 8px;
  margin: 15px 15px;
  background-color: ${grayscale[100]};
`

export const Filter = styled.div`
  &.collapsed {
    height: 120px;
  }
  border-radius: 8px;
  height: fit-content;
  padding: 15px 30px;
  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, 0.46);
`

export const FilterTitle = styled.div`
  display: flex;
  button {
    cursor: pointer;
    margin-left: 10px;
    background: none;
  }
`
