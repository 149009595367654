import React from 'react'

import * as S from './styles'

type UserFeedbackProps = {
  children: React.ReactNode
}

function UserFeedback({ children }: UserFeedbackProps) {
  return (
    <S.UserFeedbackRow>
      <S.UserFeedbackCell>{children}</S.UserFeedbackCell>
    </S.UserFeedbackRow>
  )
}

export default UserFeedback
