import { orange, grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const BillboardContainer = styled.div`
  margin-top: 2em;
  font-family: Inter;
  display: flex;
  justify-content: space-evenly;
  background-color: ${grayscale[100]};
  border-radius: 4px;
`

export const BillboardValue = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${orange.base};
`

export const BillboardLabel = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: ${grayscale.base};
`

export const BillboardValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  border-radius: 8px;
  padding: 15px 15px;
`
