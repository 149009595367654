import React from 'react'

import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { request } from '../shared/helpers'
import { LOGIN_URL } from '../config/urls'
import { DASHBOARD } from '../config/routes'

type Auth = {
  jwt: string
  user: string
  role: string
} | null

type AuthContextProps = {
  auth?: Auth
  signIn?: (location: string) => void
  signInWithSAML?: (location: string) => void
  signOut?: () => void
}

type AuthContextProviderProps = {
  children: React.ReactNode
  authentication: Auth
}

export const AuthContext = React.createContext<AuthContextProps>({})

export function AuthContextProvider({ children, authentication }: AuthContextProviderProps) {
  const [auth, setAuth] = React.useState<Auth>(authentication)
  const [cookies, , removeCookie] = useCookies()
  const history = useHistory()

  function signIn(location: string): void {
    request({
      method: 'POST',
      url: LOGIN_URL,
    }).then((response) => {
      const { jwt } = response.data
      const authData = { jwt, ...JSON.parse(atob(jwt.split('.')[1])) }

      setAuth(authData)
      sessionStorage.setItem('auth', JSON.stringify(authData))

      history.push(location)
    })
  }

  function signInWithSAML(location: string): void {
    const { jwt } = cookies

    if (jwt) {
      const authData = { jwt, ...JSON.parse(atob(jwt.split('.')[1])) }

      setAuth(authData)
      sessionStorage.setItem('auth', JSON.stringify(authData))

      history.push(location)
    }
  }

  function signOut(): void {
    setAuth(null)
    sessionStorage.removeItem('auth')
    removeCookie('jwt')
    history.push(DASHBOARD)
  }

  return (
    <AuthContext.Provider value={{ auth, signIn, signInWithSAML, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
